import axios from 'axios'

const jpserve = axios.create({
  baseURL: import.meta.env.VITE_BASE_JACKPOT_URL
})

jpserve.defaults.headers.post['Content-Type'] = 'application/json'
jpserve.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

jpserve.defaults.method = 'get'

export default jpserve