export function injectTrackingScript(event) {
  if (typeof otag !== 'function') {
    console.warn('Tracking function otag is not defined.');
    return;
  }
  try {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.textContent = `otag("event", "${event}");`;
    document.body.appendChild(script);
    console.log(`Tracking event: ${event}`);
  } catch (error) {
    console.error('Error injecting tracking script:', error);
  }
}