import { defineStore } from 'pinia';
import { getBrowser } from '@/utils/functions.js'

export const useUTMsStore = defineStore({
  id: 'UTMs',
  state: () => ({
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    referrer: '',
    utmId: '',
    utmContent: '',
    utmReferral: '',
    utmTerm: '',
    utmBrowser: '',
    gclid: '',
    ...JSON.parse(localStorage.getItem('UTMs')),
  }),
  actions: {
    setUTMData(newUTMs) {
      const shouldClear = Object.entries(newUTMs).some(([key, value]) => value && value !== this[key]);

      if (shouldClear) {
        this.clearUTMData();
      }

      Object.assign(this, newUTMs);
      localStorage.setItem('UTMs', JSON.stringify(this.$state));
    },

    clearUTMData() {
      localStorage.removeItem('UTMs');
      Object.assign(this, {
        utmSource: '',
        utmMedium: '',
        utmCampaign: '',
        referrer: '',
        utmId: '',
        utmContent: '',
        utmReferral: '',
        utmTerm: '',
        utmBrowser: '',
        gclid: '',
      });
    },

    readUTMsFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const hasNewUTMs = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id', 'utm_content', 'referrer', 'utm_referral', 'utm_term', 'gclid']
        .some(param => urlParams.has(param));

      if (!hasNewUTMs) return;

      const UTMs = {
        utmSource: urlParams.get('utm_source') || '',
        utmMedium: urlParams.get('utm_medium') || '',
        utmCampaign: urlParams.get('utm_campaign') || '',
        referrer: urlParams.get('referrer') || '',
        utmId: urlParams.get('utm_id') || '',
        utmContent: urlParams.get('utm_content') || '',
        utmReferral: urlParams.get('utm_referral') || '',
        utmTerm: urlParams.get('utm_term') || '',
        gclid: urlParams.get('gclid') || '',
        utmBrowser: getBrowser(navigator.userAgent),
      };

      console.log("UTMS", UTMs);

      this.setDeviceAndBrowser();
      this.setUTMData(UTMs);
    },

    setDeviceAndBrowser() {
      this.utmBrowser = getBrowser(navigator.userAgent);
    }
  },

  persist: true,
});
