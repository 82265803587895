import './assets/css/owl.carousel.css'
import './assets/css/owl.theme.css'
import './assets/css/appstyle.css'
import './assets/css/main-components.css'
import './assets/css/owl.transitions.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import * as Sentry from "@sentry/vue";

import pinia from './stores'
import { createHead } from '@vueuse/head'

import VeeValidate from './includes/validation.js'
import axios from 'axios'
import jQueryPlugin from './plugins/jquery'
import ScrollLoader from 'vue-scroll-loader'

import { useAuthStore } from '@/stores/auth.js'
import { useFixtureStore } from '@/stores/fixture.js'
import { useJackpotStore } from '@/stores/jackpot.js'
import { useSportStore } from '@/stores/sport.js'
import { useFreebetStore } from '@/stores/freebet.js'
import { useBalanceStore } from '@/stores/balance.js'
import { useIpAddressStore } from '@/stores/ipAddress.js'

import mitt from 'mitt'
import emitter from '@/services/eventbus.js'
import $ from 'jquery';
import { registerSW } from 'virtual:pwa-register'
import walletserve from '@/services/walletserve.js'
import { injectTrackingScript } from '@/utils/tracking.js'
import { useUTMsStore } from '@/stores/utms.js'
import { getChannelId } from '@/utils/functions.js'
import { getDeviceInfo } from '@/utils/deviceType.js'
import { setActivePinia } from "pinia";
import {trackDeposit} from "@/utils/meta.js";
import { useMenuStore } from '@/stores/menu.js'
import {hashCreate} from "@/stores/HashGenerate.js";
import { trackGA4Deposit, trackWithdrawal } from '@/utils/internalTracking.js'
import { date } from 'yup'
import fix from '@/services/fix.js'

const EventBus = mitt()

const applyInitialTheme = () => {
  const savedTheme = localStorage.getItem('theme');
  const initialTheme = savedTheme || 'light';

  document.documentElement.setAttribute('data-theme', initialTheme);
};
applyInitialTheme();

const toggleTheme = () => {
  const currentTheme = document.documentElement.getAttribute('data-theme');
  const newTheme = currentTheme === 'light' ? 'dark' : 'light';

  document.documentElement.setAttribute('data-theme', newTheme);
  localStorage.setItem('theme', newTheme);

  window.dispatchEvent(new Event('themeChanged'));
};

function preventMultiTouchGestures() {
  const handleGestureStart = (event) => {
    event.preventDefault();
  };

  const handleTouchMove = (event) => {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  };

  document.addEventListener('gesturestart', handleGestureStart);
  document.addEventListener('touchmove', handleTouchMove, { passive: false });
}

preventMultiTouchGestures();

(async function init() {
  await Promise.resolve(fix);
})();

const app = createApp(App)

Sentry.init({
  app,
  dsn: "https://b540716c8dde48c77f0a9d8c75c8a160@o4509049066684416.ingest.us.sentry.io/4509049067864064",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.2,
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const head = createHead()

function notif(message, type) {
  $.notify(
    {
      icon: 'add_alert',
      message: message
    },
    {
      type: type,
      timer: 3000,
      placement: {
        from: 'top',
        align: 'center'
      }
    }
  )
}

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}
router.beforeEach((to, from, next) => {
  if (to && to.path && to.path.startsWith('/share/') && isAndroid()) {
    const newUrl = `https://app1.maybets.com${to.fullPath}`;
    window.location.replace(newUrl);
  } else {
    if(to.path.startsWith('/mm/')){
      sessionStorage.setItem(`scrollPosition`, window.scrollY);
    }
    next();
  }
});

// const version = '9.3.7';
//
// async function clearCaches() {
//   if ('caches' in window) {
//     const cacheNames = await caches.keys();
//     await Promise.all(cacheNames.map(cache => caches.delete(cache)));
//   }
// }
//
// function clearStorage() {
//   const appVersion = localStorage.getItem('app_version');
//   localStorage.clear();
//   sessionStorage.clear();
//   if (appVersion) {
//     localStorage.setItem('app_version', appVersion);
//   }
// }
//
// async function clearIndexedDB() {
//   if ('indexedDB' in window) {
//     const databases = await indexedDB.databases();
//     await Promise.all(databases.map(db => indexedDB.deleteDatabase(db.name)));
//   }
// }
//
// async function clearSiteData() {
//   await clearCaches();
//   clearStorage();
//   await clearIndexedDB();
// }
//
// document.addEventListener('DOMContentLoaded', async () => {
//   handleBrowserDetection();
//
//   const storedVersion = localStorage.getItem('app_version');
//   if (storedVersion !== version && !storedVersion) {
//     await clearSiteData();
//     localStorage.setItem('app_version', version);
//     localStorage.setItem('update_pending', 'true');
//     console.log('New version available. Prompting user to reload.');
//   }
//
//   if (localStorage.getItem('update_pending') === 'true') {
//     showUpdateNotification();
//   }
// });
//
// function showUpdateNotification() {
//   const updateBanner = document.createElement('div');
//   updateBanner.innerHTML = `
//     <div style="
//       position: fixed;
//       top: 0;
//       left: 50%;
//       transform: translateX(-50%);
//       max-width: 540px;
//       width: 100%;
//       background: #ffcc00;
//       color: #000;
//       padding: 10px;
//       text-align: center;
//       font-size: 16px;
//       z-index: 9999;
//       border-radius: 8px;
//       box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);">
//
//       A new version of the site is available.
//       <button id="reloadBtn" style="margin-top: 10px; padding: 8px 12px; background: #8faf37; color: #fff; border: none; cursor: pointer; border-radius: 4px;">
//         Update Now
//       </button>
//     </div>
//   `;
//   document.body.appendChild(updateBanner);
//
//   document.getElementById('reloadBtn').addEventListener('click', () => {
//     localStorage.removeItem('update_pending');
//     window.location.reload();
//   });
// }
//
// window.addEventListener('storage', (event) => {
//   if (event.key === 'app_version' && event.newValue !== version) {
//     console.log('Detected version update in another tab. Reloading...');
//     window.location.reload();
//   }
// });
//
// window.addEventListener('storage', (event) => {
//   if (event.key === 'global_reload') {
//     console.log('Global reload triggered in another tab. Reloading...');
//     window.location.reload();
//   }
// });
//
// let inactivityTimer;
// function resetInactivityTimer() {
//   clearTimeout(inactivityTimer);
//   inactivityTimer = setTimeout(() => {
//     console.log('No user activity for one hour. Triggering global reload...');
//     localStorage.setItem('global_reload', Date.now());
//   }, 60 * 60 * 1000);
// }
//
// ['mousemove', 'keydown', 'click', 'scroll', 'touchstart'].forEach(eventType => {
//   window.addEventListener(eventType, resetInactivityTimer);
// });
//
// resetInactivityTimer();

// function handleBrowserDetection() {
//   const userAgent = navigator.userAgent;
//   const isOldChrome = /Chrome\/(\d+)/.test(userAgent) && parseInt(RegExp.$1) < 80;
//   const isOldFirefox = /Firefox\/(\d+)/.test(userAgent) && parseInt(RegExp.$1) < 50;
//
//   if (isOldChrome || isOldFirefox) {
//     window.location.href = 'https://lite.maybets.com';
//   }
// }

// if ('serviceWorker' in navigator) {
//   registerSW({
//     onNeedRefresh() {
//       const updateConfirmed = confirm('New version available! Refresh now?');
//       if (updateConfirmed) {
//         window.location.reload();
//       }
//     },
//     onOfflineReady() {
//       // console.log('App is ready for offline use.');
//     },
//   });
// }

// Register the service worker with update callbacks
if ('serviceWorker' in navigator) {
  registerSW({
    onNeedRefresh() {
      localStorage.setItem('sw-update', date.now());
      const updateConfirmed = confirm('New version available! Refresh now?');
      if (updateConfirmed) {
        window.location.reload();
      }
    },
    onOfflineReady() {
      console.log('App is ready for offline use.');
    },
  });
}

window.addEventListener('storage', (event) => {
  if (event.key === 'sw-update') {
    // This event fires in tabs other than the one that triggered the change
    const updateConfirmed = confirm('New version available in another tab! Refresh now?');
    if (updateConfirmed) {
      window.location.reload();
    }
  }
});

app.config.globalProperties.$EventBus = EventBus
app.config.globalProperties.$emitter = emitter
app.config.globalProperties.$setSuccess = function(message) {
  this.$emitter.emit('setSuccess', message)
}
app.config.globalProperties.$setInfo = function(message) {
  this.$emitter.emit('setInfo', message)
}
app.config.globalProperties.$setError = function(message) {
  this.$emitter.emit('setError', message)
}
app.config.globalProperties.$toggleTheme = toggleTheme;

app.config.globalProperties.getValue = function(key) {
  if (typeof Storage !== 'undefined') {
    var post = localStorage.getItem(key)
    if (post === 'undefined') {
      return ''
    }
    return post
  } else {
    return this.getCookie(key)
  }
}

app.config.globalProperties.removeSuspendedPicks = function() {
  var bSlip = useFixtureStore().getObject('bSlip')

  if (!bSlip || bSlip === '' || bSlip === '') {
    bSlip = []
  }

  var bt = []

  $.each(bSlip, function(k, v) {
    if (v !== undefined && v !== null) {
      //a suspended/deactivated outcome has active = 0 and status > 0
      if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
        bt.push(v)
      }
    }
  })

  this.saveObject('bSlip', bt)
  useFixtureStore().autoRefreshUI('removeSuspendedPicks')
}

app.config.globalProperties.goBack = function() {
  router.go(-1)
}

app.config.globalProperties.hasSuspendedFreebetPicks = function() {
  var bSlip = this.getObject('fslip')

  if (!bSlip || bSlip === '' || bSlip === '') {
    bSlip = []
  }

  var suspended = 0

  $.each(bSlip, function(k, v) {
    if (v !== undefined && v !== null) {
      //a suspended/deactivated outcome has active = 0 and status > 0
      if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
        suspended++
      }
    }
  })

  return suspended > 0
}

app.config.globalProperties.hasSuspendedPicks = function() {
  var bSlip = useFixtureStore().getObject('bSlip')

  if (!bSlip || bSlip === '' || bSlip === '') {
    bSlip = []
  }

  var suspended = 0

  $.each(bSlip, function(k, v) {
    if (v !== undefined && v !== null) {
      //a suspended/deactivated outcome has active = 0 and status > 0
      if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
        suspended++
      }
    }
  })

  return suspended > 0
}

app.config.globalProperties.logout = async function() {
  const authStore = useAuthStore();
  authStore.user = null; // Clear user data in the store

  if (authStore.logout) {
    await authStore.logout();
  }

  await router.push({ name: 'login' });

  emitter.emit('logout');
}

app.config.globalProperties.$getAuth = function() {
  try {
    const authStore = useAuthStore()
    return authStore.user?.auth ?? ''
  } catch (error) {
    return ''
  }
}

app.config.globalProperties.lockOdds = function(element,
                                                producer_id,
                                                producer_status,
                                                match_id,
                                                market_id,
                                                outcome_id,
                                                specifier,
                                                status,
                                                active,
                                                odds) {

  var oddID = this.getOddID(
    match_id,
    market_id,
    outcome_id,
    specifier,
    'odd'
  )
  var oddElement = document.getElementById(oddID)

  var oddDirection = this.getOddID(
    match_id,
    market_id,
    outcome_id,
    specifier,
    'direction'
  )
  var directionElement = document.getElementById(oddDirection)

  element.classList.remove('btn-odd')
  element.classList.remove('locked')
  element.classList.add('locked')
  element.classList.add('btn-odd')

  element.disabled = true

  if (odds !== false) {
    oddElement.setAttribute('oddValue', odds)
  }

  if (status !== false) {
    oddElement.setAttribute('oddStatus', status)
  }

  if (active !== false) {
    oddElement.setAttribute('oddActive', active)
  }

  if (
    (odds === false || status === false || active === false) &&
    producer_id === 3
  ) {
    return
  }

  oddElement.setAttribute('producerId', producer_id)
  oddElement.setAttribute('producerStatus', producer_status)

  directionElement.classList.remove('arrow')
  directionElement.classList.remove('green')
  directionElement.classList.remove('red')
  directionElement.classList.remove('up')
  directionElement.classList.remove('down')
  oddElement.textContent = '-'
  directionElement.textContent = ''
}

app.config.globalProperties.$copyToClipboard = function(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    var textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

app.config.globalProperties.$deposit = async function(amount) {
  try {
    const user = this.$getAuth()

    if (!user) {
      const menuStore = useMenuStore()
      menuStore.closeDepositModal()
      const errorMess= 'Please login to Deposit';
      emitter.emit('setInfo', errorMess);
      await router.push({ name: 'login' })
      return
    }

    if (!amount || isNaN(parseInt(amount))) {
      emitter.emit('setInfo', 'The input cannot be empty');
    }

    
    const UTMs = useUTMsStore()
    const referrer = localStorage.getItem('referrer');
    const userAgent = navigator.userAgent;
    const channel_id = getChannelId(userAgent);
    // const deviceInfo = getDeviceInfo();

    const path = import.meta.env.VITE_BASE_WALLET_URL + '/deposit/initiate'
    const data = {
      amount: parseInt(amount),
      gclid: UTMs.gclid,
      browser: String(UTMs.utmBrowser),
      utm_source: String(UTMs.utmSource),
      utm_medium: String(UTMs.utmMedium),
      utm_campaign: String(UTMs.utmCampaign),
      utm_id: String(UTMs.utmId),
      utm_referral: String(UTMs.utmReferral),
      utm_term: String(UTMs.utmTerm),
      utm_content: String(UTMs.utmContent),
      referrer: String(referrer || UTMs.referrer),
      channel_id: channel_id,
      // device_info: deviceInfo.model,
    }
    // console.log('dara', data)
    // console.log('path,', path)
    const response = await walletserve.post(path, data, {
      headers: {
        'api-key': this.$getAuth()
      }
    })


    // console.log('response', response)

    if (response.status === 200) {
      emitter.emit('setSuccess', response.data.data)

      injectTrackingScript('deposit');
      trackGA4Deposit(
        parseInt(amount),
        hashCreate(import.meta.env.VITE_KIRON_KEY)
      );
      trackDeposit(hashCreate(import.meta.env.VITE_KIRON_KEY), parseInt(amount));
    } else {
      emitter.emit('setError', 'Deposit failed')
    }
  } catch (error) {
    if (error.response) {
      emitter.emit('setError', error.response?.data?.error_message || 'Deposit failed')
      if (error.message === 'Network Error') {
        emitter.emit('error', 'Network error. Please check your internet connection and try again');
      }
      if (
        parseInt(error.response.status) === 401 ||
        parseInt(error.response.status) === 400 ||
        parseInt(error.response.status) === 428
      ) {
        await router.push({ name: 'login' })
      } else {
        emitter.emit('setError', error.response?.data?.error_message || 'Deposit failed')
      }
    }
  }
}

app.config.globalProperties.$reconcileDeposit = async function(transaction) {
  try {
    const user = this.$getAuth();

    if (!user) {
      this.$router.push({ name: 'login' });
      return false;
    }
    
    const UTMs = useUTMsStore()
    const referrer = localStorage.getItem('referrer');
    const userAgent = navigator.userAgent;
    const channel_id = getChannelId(userAgent);
    // const deviceInfo = getDeviceInfo();

    const path = import.meta.env.VITE_BASE_WALLET_URL + '/deposit/reconcile';
    const data = {
      transaction_id: String(transaction),
      gclid: UTMs.gclid,
      browser: String(UTMs.utmBrowser),
      utm_source: String(UTMs.utmSource),
      utm_medium: String(UTMs.utmMedium),
      utm_campaign: String(UTMs.utmCampaign),
      utm_id: String(UTMs.utmId),
      utm_referral: String(UTMs.utmReferral),
      utm_term: String(UTMs.utmTerm),
      utm_content: String(UTMs.utmContent),
      referrer: String(referrer || UTMs.referrer),
      channel_id: channel_id,
      // device_info: deviceInfo.model,
    };

    const response = await walletserve.post(path, data, {
      headers: {
        'api-key': this.$getAuth()
      }
    });

    if (response.status === 200) {
      emitter.emit('setSuccess', 'Deposit Queued');
      return true; // Indicate success
    } else {
      emitter.emit('setError', 'Deposit failed');
      return false; // Indicate failure
    }
  } catch (error) {
    if (error.response) {
      if (error.message === 'Network Error') {
        emitter.emit('error', 'Network error. Please check your internet connection and try again');
      }
      if (
        parseInt(error.response.status) === 401 ||
        parseInt(error.response.status) === 400 ||
        parseInt(error.response.status) === 428
      ) {
        // emitter.emit('setError', 'Your session on this device has expired');
        await useAuthStore().logout();
      } else {
        emitter.emit('setError', error.response?.data?.error_message);
      }
    }
    return false; // Indicate failure
  }
};

app.config.globalProperties.uxUpdate = async function(payload) {
  var vm = this;

  var processed = false;

  switch (payload.event) {
    case 'producer_status':
      processed = true;
      var producer_id = parseInt(payload.message.producer_id);
      var producer_status = parseInt(payload.message.producer_status);

      this.EventBus.$emit('producer:status', {
        producer_status: producer_status,
        producer_id: producer_id
      });
      break;

    case 'odds_change':
      processed = true;
      var message = payload;
      var match_id = parseInt(message.match_id);
      var markets = message.market;

      vm.jQuery.each(markets, function(k, v) {
        var market_id = parseInt(v.market_id);
        var status = v.status;
        var specifier = v.specifier === undefined || v.specifier === null ? '' : v.specifier;
        var outcome = v.outcome;

        if (outcome.length === 0) {
          var topic = `status:match-${match_id}:market-${market_id}`;
          emitter.emit(topic, {
            status: status
          });
        } else {
          vm.jQuery.each(outcome, function(key, val) {
            var outcome_id = val.outcome_id;
            var active = parseInt(val.active);
            var odd_value = val.odds;
            var previous_odds = val.previous_odds;
            var odd_id = val.odd_id;
            status = parseInt(val.status);

            var ux = {
              status: status,
              active: active,
              odds: odd_value,
              previous_odds: previous_odds,
              outcome_id: outcome_id
            };

            var oddID = vm.getOddID(match_id, market_id, outcome_id, specifier);
            var topic = `odds:${oddID}`;
            emitter.emit(topic, ux);
          });
        }
      });

      emitter.emit(`match:reload:${match_id}`, payload);
      break;

    case 'bet_stop':
      processed = true;
      var message = payload;
      var match_id = parseInt(payload.match_id);
      emitter.emit(`betstop:match-${match_id}`);
      emitter.emit(`match:reload:${match_id}`, payload);
      break;

    default:
      var match_id = parseInt(payload.match_id);
      emitter.emit(`match:reload:${match_id}`, payload);
  }

  if (!processed) {
    switch (payload.type) {
      case 'PROFILE':
        var notification = payload;

        if (notification) {
          var type = notification.type;
          var msg = notification.message;

          if (type === 'success' && !this.successDisplayed) {
            this.successDisplayed = true;
            emitter.emit('setInfo', msg);
          }

          if (type === 'error') {
            emitter.emit('error', msg);
          } else if (type === 'warning') {
            emitter.emit('setInfo', msg);
          }
          await useBalanceStore().fetchBalance();
        }
        break;
    }
  }
}

app.config.globalProperties.$withdraw = async function(amount) {
  try {
    const user = this.$getAuth()

    if (!user) {
      this.$router.push({ name: 'login' })
      return
    }

    
    const UTMs = useUTMsStore()
    const referrer = localStorage.getItem('referrer');
    const userAgent = navigator.userAgent;
    const channel_id = getChannelId(userAgent);
    // const deviceInfo = getDeviceInfo();

    const path = import.meta.env.VITE_BASE_WALLET_URL + '/withdraw'
    const data = {
      amount: parseInt(amount),
      gclid: UTMs.gclid,
      browser: String(UTMs.utmBrowser),
      utm_source: String(UTMs.utmSource),
      utm_medium: String(UTMs.utmMedium),
      utm_campaign: String(UTMs.utmCampaign),
      utm_id: String(UTMs.utmId),
      utm_referral: String(UTMs.utmReferral),
      utm_term: String(UTMs.utmTerm),
      utm_content: String(UTMs.utmContent),
      referrer: String(referrer || UTMs.referrer),
      channel_id: channel_id,
      // device_info: deviceInfo.model,
    }

    const response = await walletserve.post(path, data, {
      headers: {
        'api-key': this.$getAuth()
      }
    })

    if ([200, 201].includes(parseInt(response.status))) {
      emitter.emit('setSuccess', response?.data?.data)

      trackWithdrawal(
        parseInt(amount),
        hashCreate(import.meta.env.VITE_KIRON_KEY)
      );
    }
  } catch (error) {
    if (error.message === 'Network Error') {
      emitter.emit('error', 'Network error. Please check your internet connection and try again');
    } else if (error.response) {
      if (
        parseInt(error.response.status) === 401 ||
        parseInt(error.response.status) === 400 ||
        parseInt(error.response.status) === 428
      ) {
        emitter.emit('setInfo', 'Your session on this device has expired');
        await router.push({ name: 'login' });
      } else {
        emitter.emit('setError', error.response?.data?.error_message || 'Withdrawal failed');
      }
    }
  }
}

app.config.globalProperties.getCookie = function(ckey) {
  var key = ckey + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(key) === 0) {
      return c.substring(key.length, c.length)
    }
  }
  return ''
}

app.config.globalProperties.isMobile = function() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

app.config.globalProperties.isDesktop = function() {
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
}

app.config.globalProperties.casinoLaunchUrl = function(launch) {
  if (launch === '') {
    this.$router.push({ name: 'home' })
  } else {
    return launch
  }
}

// getIpAddress().then(ipAddress => {
//   const ipAddressStore = useIpAddressStore();
//   ipAddressStore.setIpAddress(ipAddress);
// });

app.mixin({
  methods: {
    addPick: function(
      match_id,
      date,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks,
      producer_id,
      producer_status,
      status_name,
      market_status,
      game_id,
      status,
      specifier
    ) {
      const { active, outcome_id } = picks;

      // Check if producer_id and producer_status need further resolution
      // if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
      //   return;
      // }

      // Check if picks are active or status is 1
      // if (parseInt(active) === 0) {
      //   console.log('Exiting due to inactive pick. Active status: ' + active);
      //   return;
      // }
      //
      // if (parseInt(market_status) !== 0) {
      //   console.log('Exiting due to market_status: ' + market_status);
      //   return;
      // }

      // Retrieve or initialize 'bSlip'
      let bSlip = useFixtureStore().getObject('bSlip') || [];

      // Ensure 'bSlip' is an array
      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      // Ensure 'picks' is an object
      if (typeof picks !== 'object') {
        return;
      }

      // Set properties for 'picks'
      picks.match_id = match_id;
      picks.date = date;
      picks.sport_id = useSportStore().sportId;
      picks.producer_id = producer_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.game_id = game_id;
      picks.specifier = specifier;

      const cleanSpecifier = specifier.replace(/[^a-zA-Z0-9]/g, '');
      const cleanOutcomeId = outcome_id.replace(/[^a-zA-Z0-9]/g, '');
      // Generate unique IDs using match_id, market_id, outcome_id, and specifier
      picks.id = this.getOddID(
        match_id,
        market_id,
        cleanOutcomeId,
        cleanSpecifier,
        'odd'
      );
      picks.id2 = this.getOddID(
        match_id,
        market_id,
        cleanOutcomeId,
        cleanSpecifier,
        'boosted'
      );

      // Ensure 'bSlip' length is not exceeded
      if (bSlip.length > 29) {
        this.setError('Error', 'Maximum number of games reached');
        return;
      }

      // Check for existing picks and update 'bSlip'
      let isunselect = false;

      bSlip = bSlip.filter((v) => {
        // Use match_id, outcome_id, market_id, and specifier to identify picks
        if (
          v &&
          v.match_id === match_id &&
          v.market_id === market_id &&
          v.outcome_id === outcome_id &&
          v.specifier === specifier
        ) {
          isunselect = true;
          return false; // Remove this pick
        }
        return !(v && v.match_id === match_id);

      });

      // If the pick is not already selected, add it to 'bSlip'
      if (!isunselect) {
        bSlip.push(picks);
      }

      // Save updated 'bSlip'
      this.saveObject('bSlip', bSlip);
      useFixtureStore().autoRefreshUI('addPick');

      // if (!isunselect) {
      //   bSlip.push(picks);
      //   this.saveObject('bSlip', bSlip);
      //   useFixtureStore().autoRefreshUI('addPick');
      // }
    },

    addBetHubPick: function(
      match_id,
      date,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks,
      producer_id,
      producer_status,
      status_name,
      market_status,
      game_id,
      status,
      specifier
    ) {
      const { active, outcome_id } = picks;

      // Check if producer_id and producer_status need further resolution
      // if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
      //   return;
      // }

      // Check if picks are active or status is 1
      // if (parseInt(active) === 0) {
      //   console.log('Exiting due to inactive pick. Active status: ' + active);
      //   return;
      // }
      //
      // if (parseInt(market_status) !== 0) {
      //   console.log('Exiting due to market_status: ' + market_status);
      //   return;
      // }

      // Retrieve or initialize 'bSlip'
      let bSlip = useFixtureStore().getObject('bSlip') || [];

      // Ensure 'bSlip' is an array
      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      // Ensure 'picks' is an object
      if (typeof picks !== 'object') {
        return;
      }

      // Set properties for 'picks'
      picks.match_id = match_id;
      picks.date = date;
      picks.sport_id = useSportStore().sportId;
      picks.producer_id = producer_id;
      picks.market_name = market_name;
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.game_id = game_id;
      picks.specifier = specifier;

      const cleanSpecifier = specifier.replace(/[^a-zA-Z0-9]/g, '');
      const cleanOutcomeId = outcome_id.replace(/[^a-zA-Z0-9]/g, '');
      // Generate unique IDs using match_id, market_id, outcome_id, and specifier
      picks.id = this.getOddID(
        match_id,
        market_id,
        cleanOutcomeId,
        cleanSpecifier,
        'odd'
      );
      picks.id2 = this.getOddID(
        match_id,
        market_id,
        cleanOutcomeId,
        cleanSpecifier,
        'boosted'
      );

      // Ensure 'bSlip' length is not exceeded
      if (bSlip.length > 29) {
        this.setError('Error', 'Maximum number of games reached');
        return;
      }

      // Check for existing picks and update 'bSlip'
      let isunselect = false;

      bSlip = bSlip.filter((v) => {
        // Use match_id, outcome_id, market_id, and specifier to identify picks
        if (
          v &&
          v.match_id === match_id &&
          v.market_id === market_id &&
          v.outcome_id === outcome_id &&
          v.specifier === specifier
        ) {
          isunselect = true;
          return false; // Remove this pick
        }
        return !(v && v.match_id === match_id);

      });

      // If the pick is not already selected, add it to 'bSlip'
      // if (!isunselect) {
      //   bSlip.push(picks);
      // }

      // Save updated 'bSlip'
      // this.saveObject('bSlip', bSlip);
      // useFixtureStore().autoRefreshUI('addPick');

      if (!isunselect) {
        bSlip.push(picks);
        this.saveObject('bSlip', bSlip);
        useFixtureStore().autoRefreshUI('addPick');
      }
    },

    addJackpotPick: function(
      match_id,
      market_id,
      competitor_1,
      competitor_2,
      picks,
      match_number,
      producer_id,
      jstatus
    ) {
      var { active, outcome_id, specifier } = picks

      // Check if producer_id and producer_status need further resolution
      if (parseInt(producer_id) === 1 && parseInt(jstatus) === 0) {
        /*console.log(
          "Exiting due to producer_id#" +
          producer_id +
          " producer_status#" +
          producer_status
        );*/
        return
      }

      // Check if picks are active or status is 1
      if (parseInt(active) === 0 || parseInt(jstatus) === 0) {
        // console.log('Exiting due to active#' + active + ' status#' + status)
        return
      }

      // Retrieve or initialize 'bSlip'
      var bSlip = useJackpotStore().getObject('jSlip') || []

      // Ensure 'bSlip' is an array
      if (!Array.isArray(bSlip)) {
        bSlip = []
      }

      // Set properties for 'picks'
      picks.match_id = match_id
      picks.sport_id = 1
      picks.market_id = market_id
      picks.competitor_1 = competitor_1
      picks.competitor_2 = competitor_2
      picks.match_number = match_number
      picks.id = this.getOddID(
        match_id,
        market_id,
        picks.outcome_id,
        picks.specifier,
        'jpodd'
      )

      // Ensure 'bSlip' length is not exceeded
      if (bSlip.length > 29) {
        this.setError('Error', 'Maximum number of games reached')
        return
      }

      var isunselect = false

      // Iterate over 'bSlip' to check for existing picks
      bSlip = bSlip.filter((v) => {
        if (
          v &&
          v.match_id === match_id &&
          v.market_id === market_id &&
          v.outcome_id === outcome_id &&
          v.specifier === specifier
        ) {
          isunselect = true;
          return false; // Remove this pick
        }
        return !(v && v.match_id === match_id);

      });

      // If odd is not already selected, add it to 'bSlip'
      if (!isunselect) {
        bSlip.push(picks)
      }

      // Remove undefined/null elements from 'bSlip'
      var bt = bSlip.filter(function(v) {
        return v !== undefined && v !== null
      })

      // Save updated 'bSlip'
      this.saveObject('jSlip', bt)
      useJackpotStore().autoRefreshJackpotUI('addJackpotPick')
    },

    addFreebetPick: function (
      match_id,
      market_id,
      competitor_1,
      competitor_2,
      active,
      picks
    ) {
      var {outcome_id, specifier } = picks

      if (parseInt(active) === 0) {
        // console.log("exiting due to active#" + active + " status#" + status);
        //return
      }

      // var odd_id = picks.odd_id;
      var bSlip = useFreebetStore().getObjectF("fslip") || [];
      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      let isunselect = false
      picks.match_id = match_id
      picks.market_id = market_id;
      picks.competitor_1 = competitor_1;
      picks.competitor_2 = competitor_2;
      picks.specifier = specifier;

      const cleanSpecifier = specifier.replace(/[^a-zA-Z0-9]/g, '');
      const cleanOutcomeId = outcome_id.replace(/[^a-zA-Z0-9]/g, '');

      // Generate unique IDs using match_id, market_id, outcome_id, and specifier
      picks.id = this.getOddID(
        match_id,
        market_id,
        cleanOutcomeId,
        cleanSpecifier,
        'fodd'
      );


      // Iterate over 'bSlip' to check for existing picks
      bSlip = bSlip.filter((v) => {
        if (
          v &&
          v.match_id === match_id &&
          v.market_id === market_id &&
          v.outcome_id === outcome_id &&
          v.specifier === specifier
        ) {
          isunselect = true;
          return false; // Remove this pick
        }
        return !(v && v.match_id === match_id);

      });

      // If odd is not already selected, add it to 'bSlip'
      if (!isunselect) {
        bSlip.push(picks);
      }

      // Remove undefined/null elements from 'bSlip'
      var bt = bSlip.filter(function (v) {
        return v !== undefined && v !== null;
      });
      useFreebetStore().saveObject("fslip", bt);
      useFreebetStore().autoRefreshFreebetUI("addFreebetPick");
    },

    getOddID: function(match, market, outcome, specifier, alias) {
      var id =
        alias +
        '-match-' +
        match +
        '-market-' +
        market +
        '-outcome-' +
        outcome +
        '-specifier-' +
        specifier

      id = id
        .replace(/\|/g, '-and-')
        .replace(/=/g, '-eq-')
        .replace(/\+/g, '');


      return id
    },
    notify: function(message, type) {
      //no-undef
      notif(message, type)
    },

    saveObject: function(key, value) {
      value = JSON.stringify(value)

      if (typeof Storage !== 'undefined') {
        localStorage.setItem(key, value)
      } else {
        document.cookie = key + '=' + value
      }
    },

    setError: function(title, body) {

      this.notify(body, 'danger')
    },

    setWarning: function(title, body) {

      this.notify(body, 'danger')
    },

    // setSuccess: function (title, body) {
    //
    //   this.notify(body, "success");
    // },

    closeSidebarMenu() {
      var sidenav = document.getElementById('mySidenav')
      if (sidenav) {
        sidenav.style.width = '0'
      }
    },

    goHome() {
      window.location.href="/"
    },

    Appandclose() {
      this.closeSidebarMenu();
      window.location.href = 'https://play.google.com/store/apps/details?id=com.Maybets';
    },

    setValue: function(key, value) {

      if (typeof Storage !== 'undefined') {

        localStorage.setItem(key, value)

      } else {

        document.cookie = key + '=' + value
      }
    },

    getValue: function(key) {

      if (typeof Storage !== 'undefined') {
        var post = localStorage.getItem(key)
        if (post == 'undefined') {
          return ''
        }

        return post
      } else {
        return this.getCookie(key)
      }
    },

    getCookie: function(key) {

      console.error('getCookie is not implemented | ' + key)

    },
    getClientID: function() {
      var prof = this.$getAuth()
      var n = 10

      if (!prof) {
        return (
          'anonymous-' +
          Math.floor(Math.random() * (9 * Math.pow(10, n))) +
          Math.pow(10, n)
        )
      }

      n = 5
      return (
        'profile-' +
        prof.id +
        '-' +
        Math.floor(Math.random() * (9 * Math.pow(10, n))) +
        Math.pow(10, n)
      )
    }


  }
})

async function bootstrap() {
  try {
    console.log('Initializing Pinia...');
    app.use(pinia);
    setActivePinia(pinia);
    console.log('Pinia is ready');

    app.use(router);
    app.use(VeeValidate);
    app.use(jQueryPlugin);

    app.use(ScrollLoader)
    app.use(head)

    console.log('Initialization complete. Mounting the app...');
    app.mount('#app');
    console.log('App mounted successfully!');

    const ipStore = useIpAddressStore();
    await ipStore.fetchAndSetIpAddress();
  } catch (error) {
    console.error('Error during app initialization:', error);
  }
}

bootstrap();
