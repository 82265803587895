import { defineStore } from 'pinia';
import bonusserve from '@/services/bonus.js';
import { useAuthStore } from '@/stores/auth.js';
import { debounce } from 'lodash';

export const useBonusStore = defineStore('bonus', {
  state: () => ({
    bonus: 0,
    loyaltyBonus: 0,
    bonusFetched: false,
    fetchingBonus: false
  }),
  actions: {
    async fetchBonus() {
      debounceFetchBonus();
    }
  }
});

const debounceFetchBonus = debounce(async function() {
  const bonusStore = useBonusStore();

  if (bonusStore.fetchingBonus || bonusStore.bonusFetched) {
    return;
  }

  bonusStore.fetchingBonus = true;

  try {
    const authStore = useAuthStore();
    const auth = authStore.user?.auth ?? '';

    if (!auth) {
      bonusStore.bonus = 0;
      bonusStore.loyaltyBonus = 0;
      bonusStore.fetchingBonus = false;
      return;
    }

    const path = `${import.meta.env.VITE_BASE_BONUS_URL}/bonus/balance`;
    const response = await bonusserve.get(path, {
      headers: {
        'api-key': auth
      }
    });

    bonusStore.loyaltyBonus = response.data.loyalty_balance ?? 0;
    bonusStore.bonus = (response.data.balance ?? 0) + (response.data.pending_activation ?? 0);
    bonusStore.bonusFetched = true;
  } catch (err) {
    console.error('Error fetching bonus:', err);
    bonusStore.bonus = 0;
    bonusStore.loyaltyBonus = 0;
  } finally {
    bonusStore.fetchingBonus = false;
  }
}, 300);