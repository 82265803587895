import { defineStore } from 'pinia';

export const useIpAddressStore = defineStore('ipAddress', {
  state: () => ({
    ipAddress: ''
  }),
  actions: {
    async fetchAndSetIpAddress() {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        this.setIpAddress(data.ip);
      } catch (error) {
        console.error("Failed to fetch IP address:", error);
      }
    },
    setIpAddress(ipAddress) {
      this.ipAddress = ipAddress;
    }
  }
});