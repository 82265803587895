<template>
  <div class="snow-container">
    <div
      v-for="flake in snowflakes"
      :key="flake.id"
      class="snowflake"
      :style="flake.style"
      style="width: 10px; height: auto"
    >
      ❄️
    </div>
  </div>
</template>

<script>
export default {
  name: "SnowEffect",
  data() {
    return {
      snowflakes: []
    };
  },
  mounted() {
    this.createSnowflakes();
  },
  methods: {
    createSnowflakes() {
      const numFlakes = 50;
      const flakes = [];
      for (let i = 0; i < numFlakes; i++) {
        flakes.push({
          id: i,
          style: {
            left: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 5}s`,
            animationDuration: `${3 + Math.random() * 5}s`,
            fontSize: `${8 + Math.random() * 8}px`
          }
        });
      }
      this.snowflakes = flakes;
    }
  }
};
</script>

<style scoped>
.snow-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  overflow: hidden;
}

.snowflake {
  position: absolute;
  top: -10px;
  opacity: 0.8;
  animation: fall linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-10px) rotate(0deg);
  }
  100% {
    transform: translateY(100vh) rotate(360deg);
  }
}
</style>