<template>
  <footer class="app-footer">
    <div class="container mb-4">
      <div class="row">
        <div class="footer-paybill-number">
          <router-link to="profile">
            <h2>Deposit</h2>
            <h3>Mpesa Paybill: 498098</h3>
          </router-link>
        </div>
      </div>

      <div class="row">
        <div class="footer-play-responsibly">
          <img src="/assets/images/backup/plus-18.svg" loading="lazy" width="24" height="24" alt="Play Responsibly" />
          <p>
            Play Responsibly <br />
            Not for persons under the age of 18 years.
          </p>
        </div>
      </div>

      <div class="row mb-3">
        <h3>Customer Care</h3>
        <div class="footer-social-media">
          <a href="tel:0701 001000" aria-label="Call us at 0701001000"><span>Call: 0701 001 000</span></a><br>
          <a href="mailto:care@maybets.com" aria-label="Email us at care@maybets.com"><span>Email: care@maybets.com</span></a>
        </div>
      </div>

      <div class="row mb-3">
        <h3>Join Our Community</h3>
        <div class="footer-social-media">
          <a href="https://www.facebook.com/profile.php?id=61550731703900" target="_blank" aria-label="Visit our Facebook page"><i class="bi bi-facebook"></i></a>
          <a href="https://www.instagram.com/maybetsofficial/" target="_blank" aria-label="Visit our Instagram profile"><i class="bi bi-instagram"></i></a>
          <a href="https://twitter.com/MaybetsOfficial" target="_blank" aria-label="Visit our Twitter profile">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill="#808080" d="M16.42 2H21L13.66 10.46L22.5 22H15.92L10.4 14.93L4.16 22H0.5L8.3 12.9L0 2H6.72L11.74 8.44L16.42 2ZM15.24 20H16.96L5.4 3.88H3.52L15.24 20Z"/>
            </svg>
          </a>
          <a href="https://wa.me/254704498098" target="_blank" aria-label="Chat with us on WhatsApp"><i class="bi bi-whatsapp"></i></a>
          <a href="https://t.me/maybetsofficial" target="_blank" aria-label="Join our Telegram channel"><i class="bi bi-telegram"></i></a>
          <a href="tel:0701001000" aria-label="Call us at 0701001000"><i class="bi bi-telephone-fill"></i></a>
          <a href="mailto:care@maybets.com" aria-label="Email us at care@maybets.com"><i class="bi bi-chat-dots"></i> <!-- Message (Chat Dots) Icon --></a>
        </div>
      </div>

      <div class="row">
        <h3>Our Partners</h3>
        <div class="footer-social-media">
          <img src="/assets/icon/luanda_villa.png" loading="lazy" style="width: auto; height: 60px" alt="Luanda Villas Partnership" />
          <img src="/assets/icon/intouch.png" loading="lazy" style="width: auto; height: 100px" alt="IntouchVAS Partnership" />
        </div>
      </div>

      <div class="row">
        <div class="footer-links important-links">
          <p>SparksMatrix Limited, Kenya operates the Maybets brand and authorized and regulated
            by the BCLB (Betting Control and Licensing Board) under the Betting, Lotteries and
            Gaming Act, Cap 131, Laws of Kenya under License Numbers: BK 0000680 and PG 0000453.</p>
          <!--<p>
            We use cookies and third party cookies to improve our services, analyse and personalise your preferences and to show you advertisements. If you continue the navigation, we consider that you are accepting
            its use. You can modify the settings and obtain further information in our Cookie Policy.
          </p> -->
        </div>
      </div>

      <div class="row">
        <div class="footer-links company-links">
          <router-link :to="{ name: 'help' }">How to Play</router-link>
          <router-link :to="{ name: 'terms' }">Terms & conditions</router-link>
          <router-link :to="{ name: 'policy' }">Privacy Policy</router-link>
          <router-link :to="{ name: 'responsible' }">Responsible Gambling</router-link>
          <a target="_blank" href="https://blog.maybets.com">Blog</a>
        </div>
      </div>

      <div class="row">
        <div class="footer-logo">
          <Logo />
        </div>
      </div>

      <div class="row">
        <div class="footer-rights">
          <h4>MayBets. © 2025 All rights reserved.</h4>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
  name: 'Footer',
  components: {
    Logo
  }
}
</script>