<template>
  <div class="footer-navigation">
    <div v-show="showBonusBanner" class="container text-center p-2" style="background-color: #8FAF37">
      <span style="color: #DFFF00"><strong>Add {{ remaining }} more <span v-if="remaining > 1">games</span><span v-else>game</span> with a minimum odd of 1.50 per game to utilize bonus</strong></span>
    </div>
<!--    <div :class="['betslip-counter-wrap', { 'd-none': total > 0 }]">-->
<!--      <div class="container">-->
<!--        <div class="row">-->
<!--          <div class="betslip-counter1">-->
<!--            <a data-bs-toggle="modal" data-bs-target="#myBetSlip">-->
<!--              <span class="text-black text-center">Enjoy <strong>20%</strong> Multibet <strong>Win Boost</strong> ONLY on Maybets<i class="bi bi-fire" style="color: orangered; font-size: 14px"></i></span>-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div :class="['betslip-counter-wrap', { 'd-none': total <= 0 }]">
      <div class="container">
        <div class="row">
          <div class="betslip-counter">
            <a href="#" @click.prevent="menuStore.openBetslipModal">
              <span class="odds-number text-black">Total Odds {{ odds }}</span>
              <span class="odds-number text-black">Payout: Ksh. {{ formattedPayout }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-navigation-links">
      <div class="container">
        <div class="row">
          <div class="col-12 px-0 nav-card-wrapper">
            <div class="nav-card">
              <div id="mainmenu">
                <a id="mainmenu_sidebar"
                   :class="{ activepage: $route.name === 'home' }"
                   @click="goHome()">
                  <span class="main-menu-icon"><i class="bi bi-house"></i></span>
                  <span class="main-menu-text">Home</span>
                </a>
              </div>
            </div>
            <div class="nav-card">
              <div id="livegames">
                <router-link
                    :class="{ activepage: $route.name === 'live' }"
                    :to="{ name: 'live' }">
                  <span class="main-menu-icon">
                    <i class="bi bi-play-circle-fill"></i>
                    <span class="number-of-bets">{{ liveGames }}</span>
                  </span>
                  <span class="main-menu-text">Live Games</span>
                </router-link>
              </div>
            </div>

            <div class="nav-card">
              <div id="mybets">
                <div class="betcount-left"></div>
                <div class="betcount-right"></div>
                <a href="#" id="betSlip" @click.prevent="menuStore.openBetslipModal">
                  <span class="main-menu-icon">
                    <i class="bi bi-ticket-perforated"></i>
                  </span>
                  <span class="number-of-bets">{{ total }}</span>
                </a>
              </div>
            </div>

            <div class="nav-card">
              <div id="mybets">
                <router-link
                    :class="{ activepage: $route.name === 'bets' }"
                    :to="{ name: 'bets', query: { filter: 'normalBets' } }"
                >
                  <span class="main-menu-icon">
<!--                    <span class="number-of-bets">{{ pendingBets }}</span>-->
                    <i class="bi bi-file-earmark-bar-graph"></i>
                  </span>
                  <span class="main-menu-text">My Bets</span>
                </router-link>
              </div>
            </div>
            <div class="nav-card">
              <div id="myaccount">
                <router-link
                    :class="{ activepage: $route.name === 'profile' }"
                    :to="{ name: 'profile' }">
                  <span class="main-menu-icon"><i class="bi bi-person"></i></span>
                  <span class="main-menu-text">Account</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BetSlip v-if="menuStore.showBetslipModal" @close="menuStore.closeBetslipModal" />
</template>

<script>
import { useFixtureStore } from '@/stores/fixture.js'
import { useBonusStore } from '@/stores/bonus.js'
import { useBalanceStore } from '@/stores/balance.js'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import SideNav from '@/components/Nav/SideNav.vue'
import fix from '@/services/fix.js'
import { useMenuStore } from '@/stores/menu.js'

export default {
  name: 'FooterNavigation',
  components: {
    BetSlip: defineAsyncComponent(() => import("@/views/BetSlip.vue")),
  },
  setup() {
    const MAX_PAYOUT = 1000000;
    const MINIMUM_GAMES = 7;

    const data = useFixtureStore()
    const bonusStore = useBonusStore()
    const balanceStore = useBalanceStore()
    const menuStore = useMenuStore();

    const liveGames = ref(0)
    const pendingBets = ref(0)

    const odds = computed(() => data.bSlip?.odds || 0)
    const total = computed(() => data.bSlip?.total || 0)
    const payout = computed(() => Math.min((data.bSlip?.odds * 49) || 0, MAX_PAYOUT));
    const formattedPayout = computed(() => payout.value.toFixed(2));
    const remaining = computed(() => Math.max(MINIMUM_GAMES - total.value, 0));
    const showBonusBanner = computed(
      () =>
        bonusStore.bonus > 0 &&
        balanceStore.balance > 1 &&
        total.value > 1 &&
        total.value <= 6
    );

    const fetchSports = async () => {
      try {
        const response = await fix.get(`/live/counter`)
        liveGames.value = response.data.total
      } catch (error) {
        console.error('Failed to fetch sports data:', error)
      }
    }

    const loadFromLocalStorage = () => {
      const savedOdds = JSON.parse(localStorage.getItem('betslip'))
      if (savedOdds !== null) data.bSlip = savedOdds
    }

    const goHome = () => {
      window.location.href = '/';
      console.log("go home")
    }

    watch(data.bSlip, (newbSlip) => {
      localStorage.setItem('betslip', JSON.stringify(newbSlip))
    })

    const fetchBonus = async () => {
      await bonusStore.fetchBonus()
    }

    onMounted(() => {
      loadFromLocalStorage()
      fetchSports()
      fetchBonus()
    })

    return {
      goHome,
      odds,
      total,
      formattedPayout,
      remaining,
      liveGames,
      pendingBets,
      menuStore,
      showBonusBanner,
      bonus: computed(() => bonusStore.bonus),
      balance: computed(() => balanceStore.balance),
      wbalance: computed(() => balanceStore.wbalance)
    }
  },
  methods: {
    showSidebarMenu() {
      var sidenav = document.getElementById('mySidenav')
      if (sidenav) {
        sidenav.style.width = '100%'
      }
    }
  },
  computed: {
    SideNav() {
      return SideNav
    }
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      handler(newId) {
        // console.log('Watcher in footer triggered with newId:', newId);
        if (newId) {
          const menuStore = useMenuStore()
          return menuStore.showBetslipModal = true;
        }
      }
    },
  },
}
</script>
