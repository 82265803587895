import { defineStore } from 'pinia'
import router from '@/router/index.js'
import identity from '@/services/identity.js'
import emitter from '@/services/eventbus.js'
import { useIpAddressStore } from '@/stores/ipAddress.js'
import { hashCreate } from '@/stores/HashGenerate.js'
import { injectTrackingScript } from '@/utils/tracking.js'
import { useBalanceStore } from '@/stores/balance.js'
import { useBonusStore } from '@/stores/bonus.js'
import {
  trackRegistrationInitiatedError,
  trackRegistrationInitiation
} from '@/utils/internalTracking.js'
import {
  clearAllCookies,
  deleteCookie,
  encryptData,
  getChannelId,
  getDecryptedCookieValue,
  handleMessageFromJS,
  setCookies
} from '@/utils/functions.js'

import { getDeviceInfo } from '@/utils/deviceType.js'
import { useUTMsStore } from '@/stores/utms.js'
import axios from "axios";

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: JSON.parse(getDecryptedCookieValue('user')) || null,
    msisdn: getDecryptedCookieValue('msisdn') || null,
    id: getDecryptedCookieValue('user_id') || null,
    login_tag: localStorage.getItem('login_tag') || null,
    lastErrorMessage: '',
    errorCount: 0,
    buttonDisabled: false,
    referrer: '',
  }),
  getters: {
    auth: state => state.user?.data?.auth ?? ''
  },
  actions: {
    async login(phone, password, source, referralCode, medium, campaign) {
      if (this.buttonDisabled) return;

      const path = import.meta.env.VITE_BASE_IDENTITY_URL + '/login';
      const currentDate = new Date();
      const login_tag = parseInt(
          `${currentDate.getTime()}${Math.floor(Math.random() * 900 + 100)}`,
          10
      );

      const ipAddressStore = useIpAddressStore();
      const ipAddress = ipAddressStore.ipAddress;

      const UTMs = useUTMsStore()
      const mobile = this.formatMobileNumber(phone);
      const userAgent = navigator.userAgent;
      const channel_id = getChannelId(userAgent);
      // const deviceInfo = getDeviceInfo();



      const payload = {
        campaign: String(UTMs.utmCampaign),
        medium: String(UTMs.utmMedium),
        msisdn: parseInt(mobile),
        password: String(password),
        source: String(UTMs.utmSource),
        utm_source: String(UTMs.utmSource),
        utm_medium: String(UTMs.utmMedium),
        utm_campaign: String(UTMs.utmCampaign),
        utm_id: String(UTMs.utmId),
        utm_referral: String(UTMs.utmReferral),
        utm_term: String(UTMs.utmTerm),
        referral_code: '',
        referrer: String(UTMs.referrer),
        browser: String(UTMs.utmBrowser),
        ipAddress: ipAddress,
        xp: Math.floor(Date.now() / 1000),
        channel_id: channel_id,
        // device_info: deviceInfo.model,
        gclid: UTMs.gclid,
      };

      try {
        const response = await axios.post(path, payload, {
          headers: {
            'signature-key': hashCreate(payload, import.meta.env.VITE_KIRON_KEY)
          }
        });

        if (response.status === 200) {
          // Patch the store with the returned user data.
          this.$patch({
            user: response.data,
            msisdn: mobile,
            login_tag: login_tag
          });
          const userId = this.user.id;
          this.id = userId;

          const encryptMobile = encryptData(mobile);
          const encryptID = encryptData(userId);
          const encryptUser = encryptData(JSON.stringify(response.data));

          setCookies([
            { name: 'user_id', value: encryptID },
            { name: 'user', value: encryptUser },
            { name: 'msisdn', value: encryptMobile }
          ]);
          localStorage.setItem('login_tag', login_tag);

          emitter.emit('setSuccess', 'Login successful!');
          handleMessageFromJS(response.data.auth, response.data.id);

          if (source === 'placebet') {
            const intendedRoute = localStorage.getItem('intendedRoute');
            if (intendedRoute) {
              localStorage.removeItem('intendedRoute');
              await router.push(intendedRoute);
              return;
            }
          } else {
            const intendedRoute = localStorage.getItem('intendedRoute');
            const currentDomain = new URL(window.location.href).origin;
            const referrerDomain = document.referrer ? new URL(document.referrer).origin : null;

            if (intendedRoute) {
              localStorage.removeItem('intendedRoute');
              await router.push(intendedRoute);
            } else if (referrerDomain === currentDomain) {
              router.go(-1);
            } else {
              await router.push({ name: 'home' });
            }
          }

          this.lastErrorMessage = '';
          this.errorCount = 0;

        } else if (response.status === 201 || response.status === 204) {
          trackRegistrationInitiation();
          this.msisdn = mobile;
          const encryptMobile = encryptData(mobile);
          setCookies([{ name: 'msisdn', value: encryptMobile }]);
          await router.push({ name: 'verify-code' });
        } else {
          // router.go(-1);
          await router.push({ name: 'home' })
        }
      } catch (error) {
        if (error.message === 'Network Error') {
          emitter.emit('error', 'Network error. Please check your internet connection and try again');
        } else {
          if (
              error.response &&
              (parseInt(error.response.status) === 401 ||
                  parseInt(error.response.status) === 400 ||
                  parseInt(error.response.status) === 428)
          ) {
            emitter.emit('setInfo', 'Your session on this device has expired');
            await useAuthStore().logout();
          } else {
            const errorMessage = error.response?.data?.error_message || 'Login failed. Please try again.';
            emitter.emit('error', errorMessage);

            if (errorMessage === this.lastErrorMessage) {
              this.errorCount += 1;
            } else {
              this.errorCount = 1;
              this.lastErrorMessage = errorMessage;
            }

            if (this.errorCount >= 2) {
              this.buttonDisabled = true;
              setTimeout(() => {
                this.buttonDisabled = false;
              }, 5000);
            }
          }
        }
      }
    },

    async register(userData) {
      const path = import.meta.env.VITE_BASE_IDENTITY_URL + '/signup'

      const UTMs = useUTMsStore()
      if (userData.referrer) {
        localStorage.setItem('referrer', userData.referrer);
        this.referrer = userData.referrer || UTMs.referrer;
      }

      const mobile = this.formatMobileNumber(userData.msisdn)

      const userAgent = navigator.userAgent;
      const channel_id = getChannelId(userAgent);
      // const deviceInfo = getDeviceInfo();


      const payload = {
        browser: String(UTMs.utmBrowser),
        campaign: String(UTMs.utmCampaign),
        first_name: '',
        last_name: '',
        msisdn: parseInt(mobile),
        password: String(userData.password),
        utm_source: String(UTMs.utmSource),
        utm_medium: String(UTMs.utmMedium),
        utm_campaign: String(UTMs.utmCampaign),
        utm_id: String(UTMs.utmId),
        utm_referral: String(UTMs.utmReferral),
        referrer: String(this.referrer),
        source: String(UTMs.utmSource),
        referral_code: '',
        xp: Math.floor(Date.now() / 1000),
        channel_id: channel_id,
        // device_info: deviceInfo.model,
        gclid: UTMs.gclid
      }

      try {
        const response = await identity.post(path, payload, {
          headers: {
            'signature-key': hashCreate(payload, import.meta.env.VITE_KIRON_KEY),
          }
        })
        if (response.status === 201) {
          var msg = response.data.data

          const userId = response.data.id;
          this.id = userId;
          this.msisdn = mobile

          const encryptMobile = encryptData(mobile);
          const encryptID = encryptData(userId)

          setCookies([
            { name: 'user_id', value: encryptID },
            { name: 'msisdn', value: encryptMobile },
          ]);

          this.lastErrorMessage = '';
          this.errorCount = 0;

          emitter.emit('setSuccess', msg)
          await router.push({ name: 'verify-code' })

          trackRegistrationInitiation(userId);
          injectTrackingScript('registration');

        } else {
          // router.go(-1)
          await router.push({ name: 'home' })
        }
      } catch (error) {

        const errorCode = error.response?.status || '';
        const errorMessage = error.response?.data?.error_message || 'Network Error occurred';
        console.log("Error", error)
        trackRegistrationInitiatedError(errorCode, errorMessage);
        if (error.message === 'Network Error') {
          emitter.emit('error', 'Network error. Please check your internet connection and try again');
        } else {
          const errorMessage = error.response?.data?.error_message || 'Registration failed. Please try again.';

          if (error.response && parseInt(error.response.status) === 400) {
            emitter.emit('setError', errorMessage);
            await router.push({ name: 'login' })
          } else if (
              error.response &&
              (parseInt(error.response.status) === 401 || parseInt(error.response.status) === 428)
          ) {
            emitter.emit('setInfo', 'Your session on this device has expired');
            await useAuthStore().logout();
          } else {
            emitter.emit('error', errorMessage);
          }

          if (errorMessage === this.lastErrorMessage) {
            this.errorCount += 1;
          } else {
            this.errorCount = 1;
            this.lastErrorMessage = errorMessage;
          }

          if (this.errorCount >= 2) {
            this.buttonDisabled = true;
            setTimeout(() => {
              this.buttonDisabled = false;
            }, 5000);
          }
        }
      }
    },

    isLoggedIn() {
      return this.user !== null;
    },

    async logout() {
      this.user = null
      this.login_tag = null
      localStorage.removeItem('login_tag')
      localStorage.removeItem('isNavClosed')
      localStorage.removeItem('gclid');
      localStorage.removeItem('hideDownloadBanner_615335')

      localStorage.clear()
      sessionStorage.clear()
      clearAllCookies()
      // clearUTMData()

      const balanceStore = useBalanceStore();
      balanceStore.balance = 0;
      balanceStore.wbalance = 0;
      balanceStore.sessionExpired = false;
      balanceStore.balanceFetched = false;
      balanceStore.fetchingBalance = false;
      balanceStore.summaryFetched = false;
      balanceStore.fetchingSummary = false;
      balanceStore.summaryData = 0;

      const bonusStore = useBonusStore();
      bonusStore.bonus = 0;
      bonusStore.loyaltyBonus = 0;
      bonusStore.bonusFetched = false;
      bonusStore.fetchingBonus = false;

      await router.push({ name: 'home' })
    },

    formatMobileNumber(mobile) {
      mobile = mobile.replace(/\s+/g, '');
      if (mobile.charAt(0) === '+') {
        mobile = mobile.substring(1);
      }
      return mobile;
    },

    clearMsisdn() {
      this.msisdn = null
      deleteCookie('msisdn');
    },

    setUser(userData) {
      this.user = userData;
      const encryptUser = encryptData(JSON.stringify(userData))
      setCookies([{ name: 'user', value: encryptUser }]);
    }
  }
})