<script>
import { computed, ref } from 'vue'
import { useJackpotStore } from '@/stores/jackpot.js'
import { useAuthStore } from '@/stores/auth.js'
import router from '@/router/index.js'
import $ from 'jquery'
import emitter from '@/services/eventbus.js'
import { useIpAddressStore } from '@/stores/ipAddress.js'
import { useUTMsStore } from '@/stores/utms.js'
import { getChannelId } from '@/utils/functions.js'
import { getDeviceInfo } from '@/utils/deviceType.js'
import { useBalanceStore } from '@/stores/balance.js'

export default {
  name: 'JackpotFooter',
  setup() {
    const jackpotStore = useJackpotStore()
    const ipAddressStore = useIpAddressStore()
    const isBetDisabled = ref(false)

    const total = computed(() => jackpotStore.jSlip?.total || 0)
    const jackpot = {
      prize: jackpotStore.jackpotData ? jackpotStore.jackpotData.prize : 0,
      stake: jackpotStore.jackpotData ? jackpotStore.jackpotData.stake : 0,
      start_time: jackpotStore.jackpotData ? jackpotStore.jackpotData.start : '',
      end_time: jackpotStore.jackpotData ? jackpotStore.jackpotData.end : '',
      number_of_games: jackpotStore.jackpotData ? jackpotStore.jackpotData.number_of_games : 0
    }

    const hasGames = computed(() => {
      return jackpotStore.jackpotData && jackpotStore.jackpotData.number_of_games > 0
    })

    const placebet = async () => {
      if (isBetDisabled.value) return

      isBetDisabled.value = true

      const authStore = useAuthStore()
      if (!authStore.user) {
        await router.push({ name: 'login' })
        emitter.emit('setInfo', 'Login to continue')
        isBetDisabled.value = false
        return
      }

      let bets = []
      $.each(jackpotStore.jSlip.picks, function(index, pick) {
        bets.push({
          match_number: pick.match_number,
          match_id: pick.match_id,
          market_id: pick.market_id,
          outcome_id: pick.outcome_id,
          specifier: pick.specifier,
          producer_id: 3,
        })
      })

      const ipAddress = ipAddressStore.ipAddress
      const UTMs = useUTMsStore()
      const referrer = localStorage.getItem('referrer')
      const userAgent = navigator.userAgent
      const channel_id = getChannelId(userAgent)
      // const deviceInfo = getDeviceInfo();

      const payload = {
        bet_type: 1,
        bets: bets,
        booking_code: '',
        campaign: '',
        ip_address: ipAddress,
        medium: '',
        source: 1,
        stake: parseInt(jackpotStore.jackpotData.stake),
        stake_type: '',
        utm_source: String(UTMs.utmSource),
        useragent: JSON.stringify(navigator.userAgent),
        browser: String(UTMs.utmBrowser),
        utm_medium: String(UTMs.utmMedium),
        utm_campaign: String(UTMs.utmCampaign),
        utm_id: String(UTMs.utmId),
        utm_referral: String(UTMs.utmReferral),
        utm_term: String(UTMs.utmTerm),
        utm_content: String(UTMs.utmContent),
        referrer: String(referrer || UTMs.referrer),
        channel_id: channel_id,
        gclid: UTMs.gclid
        // device_info: deviceInfo.model,
      }

      if (jackpotStore.jackpotData.jackpot_category_id === 6) {
        if (payload.bets.length !== 13) {
          emitter.emit('error', 'Please select exactly 13 games for Weekly Jackpot.')
          isBetDisabled.value = false
          return
        }
      } else if (jackpotStore.jackpotData.jackpot_category_id === 5) {
        if (payload.bets.length !== 10) {
          emitter.emit('error', 'Please select exactly 10 games for Daily Jackpot.')
          isBetDisabled.value = false
          return
        }
      }

      try {
        const response = await jackpotStore.placeBet(payload)
        emitter.emit('setSuccess', response.data)
        const balanceStoreInstance = useBalanceStore()
        balanceStoreInstance.balanceFetched = false
        await balanceStoreInstance.fetchBalance()
      } catch (error) {
        emitter.emit('setError', 'Failed to place bet. Please try again.')
      } finally {
        setTimeout(() => {
          isBetDisabled.value = false
        }, 10000)
      }
    }

    const clearBetSlip = () => {
      jackpotStore.clearJackpotBetSlip()
    }
    const autoPick = () => {
      jackpotStore.autoPick()
    }

    return {
      total,
      jackpot,
      placebet,
      clearBetSlip,
      autoPick,
      hasGames,
      isBetDisabled
    }
  }
}
</script>

<template>
  <div v-if="hasGames" class="footer-navigation">
    <div :class="['jbetslip-counter-wrap', { 'd-none': total <= 0 }]">
      <div class="container">
        <div class="row">
          <div class="betslip-counter">
            <span class="games-number">{{ total }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="placebet-wrapper position-static">
      <div class="container pt-2">
        <div class="row align-items-center gx-0">
          <div class="col-4">
            <div class="autopick-betslip">
              <div @click="autoPick" class="text-center">
                <span class="icon"><i class="bi bi-shuffle"></i></span>
                <h6>Auto Pick</h6>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="placebet-btn">
              <div class="view-betslip">
                <button class="btn btn-success w-100" @click="placebet" :disabled="isBetDisabled">
                  Place Bet
                </button>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="autopick-betslip">
              <div @click="clearBetSlip" class="text-center">
                <span class="icon"><i class="bi bi-trash"></i></span>
                <h6>Clear Bet</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.jbetslip-counter-wrap {
  width: 100%;
  padding: 3px;
  background-color: #9ce800;
  color: #283441;
}

.autopick-betslip a h6 {
  font-size: 12px;
  font-weight: 800;
}
</style>