import { defineStore } from 'pinia'

export const useSportStore = defineStore('sport', {
  state: () => ({
    sportId: 1,
    category_id: 0,
    selectedTab: 'popular',
    match_id: 0,
    sportsMap: {
      Football: 1,
      Basketball: 2,
      Rugby: 12,
      Volleyball: 23,
      Tennis: 5,
      Netball: 35,
      Badminton: 31,
      Handball: 6,
      Cricket: 21,
      'NFL': 16,
      Baseball: 3,
      Darts: 22,
      'IceHockey': 4,
      MMA: 117,
      Esoccer: 137,
      Boxing: 10,
      ESoccer: 137
    },
  }),
  getters: {
    getSportId(state) {
      return state.sportId
    }
  },
  actions: {
    updateSportId(newSportId) {
      this.sportId = newSportId
    },
    updateMatchId(matchId) {
      this.match_id = matchId
    },
    updateCategory(category_id) {
      this.category_id = category_id
    },
    updateSelectedTab(tab) {
      this.selectedTab = tab
    }
  }
})