<template>
  <div class="main-logo">
    <router-link to="/">
      <img :src="logoSrc" alt="Maybets Logo" />
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isDarkMode: false,
    };
  },
  computed: {
    logoSrc() {
      return this.isDarkMode
        ? '/assets/images/maybetslight.webp'
        : '/assets/images/backup/maybetslogo.png';
    },
  },
  mounted() {
    this.isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark';
    const observer = new MutationObserver(() => {
      this.isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark';
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });
  },
};
</script>