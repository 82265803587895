/**
 * Track a custom event using Meta Pixel.
 * @param {string} eventName - The name of the event.
 * @param {object} eventData - Additional event data.
 */
export function trackEvent(eventName, eventData = {}) {
    if (typeof fbq === 'function') {
        fbq('trackCustom', eventName, eventData);
    } else {
        console.warn('Meta Pixel (fbq) is not available.');
    }
}

/**
 * Track a bet event.
 * @param {string} betId - Unique identifier for the bet.
 * @param {number} betAmount - Amount placed on the bet.
 */
export function trackBet(betId, betAmount) {
    console.log('Tracking bet ', betId, betAmount);
    trackEvent('BetPlaced', { betId, betAmount });
}

/**
 * Track a deposit event.
 * @param {string} uniqueId - Unique identifier for the deposit.
 * @param {number} depositAmount - Amount deposited.
 */
export function trackDeposit(uniqueId, depositAmount) {
    console.log("Deposit", uniqueId, depositAmount);
    trackEvent('DepositMade', { uniqueId, depositAmount });
}

/**
 * Track a registration event.
 * @param {string} uniqueId - Unique identifier for the user.
 * @param {object} userData - Additional user data.
 */
export function trackRegistration(uniqueId, userData = {}) {
    trackEvent('Registration', { uniqueId, ...userData });
}

/**
 * Track aviator event.
 * @param {string} uniqueId - Unique identifier for the user.
 * @param {string} casinoGame - Unique name for the casinoGame.
 */
export function trackCasino(uniqueId, casinoGame) {
    // console.log("Casino", uniqueId, casinoGame);
    trackEvent('CasinoGames', { uniqueId, casinoGame });
}

/**
 * Track aviator event.
 * @param {string} uniqueId - Unique identifier for the user.
 */
export function trackAviator(uniqueId) {
    trackEvent('Aviator', { uniqueId });
}

/**
 * Track fun-mode aviator event.
 * @param {string} funmodedata - Unique identifier for the user.
 */
export function trackFunModeAviator(funmodedata) {
    trackEvent('FunModeAviator', { funmodedata });
}

/**
 * Track a ligisoobet event.
 * @param {string} betId - Unique identifier for the bet.
 * @param {number} betAmount - Amount placed on the bet.
 */
export function trackLigiSoo(betId, betAmount) {
    // console.log("trackLigiSoo", betId, betAmount);
    trackEvent('LigiSooBet', { betId, betAmount });
}
