// src/mqttService.js
import mqtt from 'mqtt'
import {useBalanceStore} from "@/stores/balance.js";

const options = {
  host: import.meta.env.VITE_URL_MQTT_HOST,
  port: 443,
  clean: true,
  connectTimeout: 4000,
  protocol: 'wss', // secure connection
  username: import.meta.env.VITE_URL_MQTT_USER,
  password: import.meta.env.VITE_URL_MQTT_PASS,
  reconnectPeriod: 1000,
  path: '/mqtt'
}

const client = mqtt.connect(options)

client.on('connect', () => {
  // console.log('Connected to MQTT broker');
})

client.on('offline', () => {
  console.warn('MQTT client is offline')
})

client.on('reconnect', () => {
  // console.log('Reconnecting to MQTT broker')
})

client.on('close', () => {
  // Connection closed
})

client.on('message', (topic, message) => {
  const msgString = message.toString();
  // console.log(`Received message on topic ${topic}: ${msgString}`);
  try {
    const payload = JSON.parse(msgString);
    if (payload.message !== null) {
      // console.log("Profile update received:", payload.message);
      const balanceStore = useBalanceStore();
      balanceStore.resetBalanceFetched();
      balanceStore.fetchBalance();
    }
  } catch (error) {
    console.error("Error parsing MQTT message:", error);
  }
});

client.on('error', (err) => {
  console.error('MQTT connection error: ', err);
  client.end()
})

export default client
