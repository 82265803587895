<template>
  <div>
<!--    <SnowEffect />-->
    <div>
      <div v-if="!isPrintResult">
        <div id="app-container" v-if="!isSpecialRoute">
          <div class="app">
            <SideNav v-if="showSideMenu" @close="menuStore.closeSideMenu" />
            <Header v-if="!isCasinoGamesRoute && showHeader" />
            <GlobalAlert />

            <!-- Main Content -->
            <div class="main-app-content" id="mydivon">
<!--              <Popup />-->
              <router-view></router-view>

              <!-------------------Footer Start----------------->
              <Footer v-if="showFooter" />
            </div>
            <!-- End Main Content -->

            <!-------------------Footer Navigation----------------->
            <FooterNavigation v-if="showFooter && !isJackpotPage && !isSambaza &&!isAviatorMiles" />
            <JackpotFooter v-if="isJackpotPage" />

            <!-------------------Sports navigation Modal----------------->
            <SportsMenu v-if="showSportsMenu" @close="menuStore.closeSportsMenu" />
            <SportsMenuCasino v-if="showCasinoSportsMenu" @close="menuStore.closeSportsMenuCasino" />
          </div>
        </div>
        <Aviator v-else-if="isAviatorRoute" />
        <AviatorDemo v-else-if="isAviatorDemoRoute" />
        <Virtuals v-else-if="isVirtualsRoute" />
        <Livescore v-else-if="isLivescoreRoute" />
        <Spin2Win v-else-if="isSpin2WinRoute" />
        <Comet v-else-if="isCometRoute" />
        <Aviatrix v-else-if="isAviatrixRoute" />
        <Limoon v-else-if="isLimoonRoute" />
        <Logifuture v-else-if="isLogifutureRoute" />
        <Instant v-else-if="isInstantRoute" />
        <EuroVirtual v-else-if="isEuroVirtualsRoute" />
        <Champions v-else-if="isChampionsRoute" />
        <afcon-virtuals v-else-if="isAfconVirtualsRoute" />
        <EnglandOnDemand v-else-if="isEnglandOnDemandRoute" />
        <Englandleague v-else-if="isEnglandleagueRoute" />
        <CasinoGames v-else />
      </div>
      <div v-else>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth.js'
import Header from '@/components/Header.vue'
import Footer from '@/components/Nav/Footer.vue'
import FooterNavigation from '@/components/Nav/FooterNavigation.vue'
// import SideNav from '@/components/Nav/SideNav.vue'
// import SportsMenu from '@/components/Nav/SportsMenu.vue'
import JackpotFooter from '@/components/Jackpot/JackpotFooter.vue'
import GlobalAlert from '@/components/GlobalAlert.vue'
// import Popup from '@/components/Popup.vue'
import SnowEffect from '@/components/Snow.vue'
import { useUTMsStore } from '@/stores/utms';
import { useMenuStore } from '@/stores/menu.js'
import {useReferralStore} from "@/stores/referral.js";

const SideNav = defineAsyncComponent( () => import('@/components/Nav/SideNav.vue'))
const SportsMenu = defineAsyncComponent( () => import('@/components/Nav/SportsMenu.vue'))
const SportsMenuCasino = defineAsyncComponent( () => import('@/components/Nav/SportsMenuCasino.vue'))
const Livescore = defineAsyncComponent(() => import('@/views/Livescore.vue'))
const Aviator = defineAsyncComponent(() => import('@/views/Aviator.vue'))
const AviatorDemo = defineAsyncComponent(() => import('@/views/Aviator-demo.vue'))
const Spin2Win = defineAsyncComponent(() => import('@/views/Spin2Win.vue'))
const CasinoGames = defineAsyncComponent(() => import('@/views/Casinogames.vue'))
const Virtuals = defineAsyncComponent(() => import('@/views/Virtuals.vue'))
const Comet = defineAsyncComponent(() => import('@/views/Comet.vue'))
const Aviatrix = defineAsyncComponent(() => import('@/views/Aviatrix.vue'))
const Logifuture = defineAsyncComponent(() => import('@/views/Logifuture.vue'))
const Instant = defineAsyncComponent(() => import('@/views/Instant.vue'))
const AfconVirtuals = defineAsyncComponent(() => import('@/Virtuals/AfconVirtuals.vue'))
const Champions = defineAsyncComponent(() => import('@/views/Champions.vue'))
const CopaAmerica = defineAsyncComponent(() => import('@/Virtuals/CopaAmerica.vue'))
const EnglandOnDemand = defineAsyncComponent(() => import('@/Virtuals/England0nDemand.vue'))
const Englandleague = defineAsyncComponent(() => import('@/Virtuals/EnglandVirtuals.vue'))
const EuroVirtual = defineAsyncComponent(() => import('@/Virtuals/EuroVirtuals.vue'))
const ItalyLeague = defineAsyncComponent(() => import('@/Virtuals/ItalyLeague.vue'))
const JetX = defineAsyncComponent(() => import('@/Virtuals/JetX.vue'))
const Keno = defineAsyncComponent(() => import('@/Virtuals/Keno.vue'))
const Limoon = defineAsyncComponent(() => import('@/views/Limoon.vue'))
const Numbers = defineAsyncComponent(() => import('@/Virtuals/Numbers.vue'))
const Roulette = defineAsyncComponent(() => import('@/Virtuals/Roulette.vue'))
const SpainLeague = defineAsyncComponent(() => import('@/Virtuals/SpainLeague.vue'))
const WorldCup = defineAsyncComponent(() => import('@/Virtuals/WorldCup.vue'))

export default {
  name: 'App',
  components: {
    SnowEffect,
    // Popup,
    Englandleague,
    CasinoGames,
    Spin2Win,
    GlobalAlert,
    JackpotFooter,
    Header,
    FooterNavigation,
    Footer,
    SideNav,
    SportsMenu,
    SportsMenuCasino,
    Aviator,
    AviatorDemo,
    Livescore,
    Instant,
    Comet,
    Logifuture,
    Aviatrix,
    Limoon,
    Virtuals,
    AfconVirtuals,
    Champions,
    CopaAmerica,
    EnglandOnDemand,
    EuroVirtual,
    ItalyLeague,
    JetX,
    Keno,
    Numbers,
    Roulette,
    SpainLeague,
    WorldCup
  },
  setup() {
    // const showSplash = ref(true);
    const menuStore = useMenuStore();
    const utmStore = useUTMsStore();
    let referral = useReferralStore()
    let user = useAuthStore()
    const router = useRouter();

    const showSideMenu = computed(() => menuStore.showSideMenu);
    const showSportsMenu = computed(() => menuStore.showSportsMenu);
    const showCasinoSportsMenu = computed(() => menuStore.showCasinoSportsMenu);


    async function initializeUTMs() {
      await new Promise((resolve) => setTimeout(resolve, 0));
      await utmStore?.readUTMsFromURL();
    }

    // async function initializeUTMs() {
    //   await new Promise((resolve) => setTimeout(resolve, 0));
    //   await utmStore?.readUTMsFromURL();
    //   if(router !== undefined){
    //     router.afterEach(async () => {
    //       await initializeUTMs();
    //     });
    //   }
    //
    // }

    onMounted(async () => {
      await initializeUTMs();

      router.afterEach(async () => {
        await initializeUTMs();
      });

      if (user.user?.auth){
        await referral.fetchReferralCode()
      }

      await utmStore?.setDeviceAndBrowser();

      const params = new URLSearchParams(window.location.search);
      const gclid = params.get('gclid');
      if (gclid) {
        localStorage.setItem('gclid', gclid);
      }

      // if (!localStorage.getItem('visited')) {
      //   setTimeout(() => {
      //     showSplash.value = false
      //     localStorage.setItem('visited', 'true')
      //   }, 6000)
      // } else {
      //   showSplash.value = false
      // }

      // if (route.name === 'home') {
      //   window.addEventListener('beforeunload', handleBeforeUnload);
      // }
    })

    // onUnmounted(() => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // });

    // const handleBeforeUnload = (event) => {
    //   event.preventDefault();
    //   event.returnValue = '';
    // };
    const route = useRoute()
    const showFooter = computed(() => !route.meta.hideFooter)
    const showHeader = computed(() => !route.meta.hideHeader)
    const isJackpotPage = computed(() => route.name === 'jackpot')
    const isSambaza = computed(() => route.name === 'verifystake')
    const isAviatorMiles = computed(() => route.name === 'aviator2')
    const isCasinoGamesRoute = computed(() => route.name === 'casinogames')
    const isPrintResult = computed(() => route.name === 'print-results')
    const isAviatorRoute = computed(() => route.name === 'aviator')
    const isAviatorDemoRoute = computed(() => route.name === 'aviatordemo')
    const isLivescoreRoute = computed(() => route.name === 'livescore')
    const isCometRoute = computed(() => route.name === 'comet')
    const isVirtualsRoute = computed(() => route.name === 'virtuals')
    const isSpin2WinRoute = computed(() => route.name === 'spin2win')
    const isAviatrixRoute = computed(() => route.name === 'aviatrix')
    const isLimoonRoute = computed(() => route.name === 'limoon')
    const isInstantRoute = computed(() => route.name === 'instant')
    const isLogifutureRoute = computed(() => route.name === 'logifuture')
    const isAfconVirtualsRoute = computed(() => route.name === 'afconVirtuals')
    const isChampionsRoute = computed(() => route.name === 'champions')
    const isCopaAmericaRoute = computed(() => route.name === 'copaamerica')
    const isEnglandOnDemandRoute = computed(() => route.name === 'englandOnDemand')
    const isEnglandleagueRoute = computed(() => route.name === 'englandleague')
    const isEuroVirtualsRoute = computed(() => route.name === 'euroVirtuals')
    const isItalyLeagueRoute = computed(() => route.name === 'ItalyLeague')
    const isJetXRoute = computed(() => route.name === 'JetX')
    const isKeno = computed(() => route.name === 'Keno')
    const isNumbers = computed(() => route.name === 'Numbers')
    const isRouletteRoute = computed(() => route.name === 'Roulette')
    const isSpainLeagueRoute = computed(() => route.name === 'SpainLeague')
    const isWorldCup = computed(() => route.name === 'WorldCup')
    const isSpecialRoute = computed(() =>
      isCasinoGamesRoute.value || isAviatorRoute.value || isAviatorDemoRoute.value || isLivescoreRoute.value || isInstantRoute.value || isLogifutureRoute.value ||
      isSpin2WinRoute.value || isAviatrixRoute.value || isLimoonRoute.value || isVirtualsRoute.value || isCometRoute.value || isAfconVirtualsRoute.value ||
      isChampionsRoute.value || isCopaAmericaRoute.value || isEnglandOnDemandRoute.value || isEnglandleagueRoute.value || isEuroVirtualsRoute.value || isItalyLeagueRoute.value ||
      isJetXRoute.value || isKeno.value || isNumbers.value || isRouletteRoute.value || isSpainLeagueRoute.value || isWorldCup.value
    )

    return {
      // showSplash,
      isJackpotPage,
      isSambaza,
      isAviatorMiles,
      showFooter,
      showHeader,
      isAviatorRoute,
      isAviatorDemoRoute,
      isLivescoreRoute,
      isCometRoute,
      isVirtualsRoute,
      isAviatrixRoute,
      isLimoonRoute,
      isAfconVirtualsRoute,
      isChampionsRoute,
      isCopaAmericaRoute,
      isEnglandOnDemandRoute,
      isEnglandleagueRoute,
      isEuroVirtualsRoute,
      isItalyLeagueRoute,
      isJetXRoute,
      isKeno,
      isRouletteRoute,
      isSpainLeagueRoute,
      isWorldCup,
      isNumbers,
      isInstantRoute,
      isLogifutureRoute,
      isSpin2WinRoute,
      isCasinoGamesRoute,
      isPrintResult,
      isSpecialRoute,
      menuStore,
      showSideMenu,
      showSportsMenu,
      showCasinoSportsMenu,
    }
  }
}
</script>

<style scoped>

@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 18em;
  }
}

@keyframes blink {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: transparent;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>
