import axios from 'axios'

const bonusserve = axios.create({
  baseURL: import.meta.env.VITE_BASE_BONUS_URL
})

bonusserve.defaults.headers.post['Content-Type'] = 'application/json'
bonusserve.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

bonusserve.defaults.method = 'get'

export default bonusserve