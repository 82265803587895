import { defineStore } from 'pinia'

export const useTabStore = defineStore({
  id: 'tab',
  state: () => ({
    selectedTab: 'popular',
    categoryId: 0,
    tournamentId: 0
  }),
  actions: {
    setSelectedTab(tab) {
      this.selectedTab = tab
    },
    setCategoryId(categoryId) {
      this.categoryId = categoryId
    },
    setTournamentId(tournamentId) {
      this.tournamentId = tournamentId
    }
  }
})