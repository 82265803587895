import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useMenuStore = defineStore('menu', () => {
  const showSportsMenu = ref(false)
  const showCasinoSportsMenu = ref(false)
  const showSideMenu = ref(false)
  const showDepositModal = ref(false)
  const showBetslipModal = ref(false)

  function openSportsMenu() {
    console.log('Status just before SportsMenu open', showSideMenu.value);
    showSportsMenu.value = true
    setTimeout(() => {
      const modal = document.getElementById('otherMenuOverlay')

      if (!modal) {
        console.error('Modal element not found!')
        return
      }

      const modalInstance = bootstrap.Modal.getOrCreateInstance(modal)

      modal.addEventListener(
        'hidden.bs.modal',
        () => {
          document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove())

          modal.setAttribute('inert', '')
          modal.setAttribute('aria-hidden', 'true')

          const shareBetModalElement = document.getElementById('otherMenuOverlay')
          if (shareBetModalElement) {
            const newModalInstance = bootstrap.Modal.getOrCreateInstance(shareBetModalElement)
            newModalInstance.show()

            shareBetModalElement.removeAttribute('inert')
            shareBetModalElement.setAttribute('aria-hidden', 'false')
            shareBetModalElement.focus()
          }
        },
        { once: true } // Ensures the event listener runs only once
      )

      modalInstance.show()
    }, 100)
    console.log('Status just before SportsMenu open', showSportsMenu.value);
  }

  function closeSportsMenu() {
    const modal = document.getElementById("otherMenuOverlay");

    if (!modal) {
      console.error("Modal element not found!");
      return;
    }

    const modalInstance = bootstrap.Modal.getInstance(modal);

    if (modalInstance) {
      modalInstance.hide();
    }

    // Remove backdrop manually (Bootstrap sometimes fails to remove it)
    document.querySelectorAll(".modal-backdrop").forEach((backdrop) => backdrop.remove());

    // Restore modal attributes
    modal.setAttribute("inert", "");
    modal.setAttribute("aria-hidden", "true");
    document.body.removeAttribute("class");

    setTimeout(() => {
      document.body.removeAttribute("style");
    }, 10);

    showSportsMenu.value = false;
  }

  function openSportsMenuCasino() {
    console.log('Status just before SportsMenuCasino open', showCasinoSportsMenu.value);
    showCasinoSportsMenu.value = true
    setTimeout(() => {
      const modal = document.getElementById('otherMenuOverlayCasino')

      if (!modal) {
        console.error('Modal element not found!')
        return
      }

      const modalInstance = bootstrap.Modal.getOrCreateInstance(modal)

      modal.addEventListener(
        'hidden.bs.modal',
        () => {
          document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove())

          modal.setAttribute('inert', '')
          modal.setAttribute('aria-hidden', 'true')

          const shareBetModalElement = document.getElementById('otherMenuOverlayCasino')
          if (shareBetModalElement) {
            const newModalInstance = bootstrap.Modal.getOrCreateInstance(shareBetModalElement)
            newModalInstance.show()

            shareBetModalElement.removeAttribute('inert')
            shareBetModalElement.setAttribute('aria-hidden', 'false')
            shareBetModalElement.focus()
          }
        },
        { once: true } // Ensures the event listener runs only once
      )

      modalInstance.show()
    }, 100)
    console.log('Status just before SportsMenuCasino open', showCasinoSportsMenu.value);
  }

  function closeSportsMenuCasino() {
    const modal = document.getElementById("otherMenuOverlayCasino");

    if (!modal) {
      console.error("Modal element not found!");
      return;
    }

    const modalInstance = bootstrap.Modal.getInstance(modal);

    if (modalInstance) {
      modalInstance.hide();
    }

    // Remove backdrop manually (Bootstrap sometimes fails to remove it)
    document.querySelectorAll(".modal-backdrop").forEach((backdrop) => backdrop.remove());

    // Restore modal attributes
    modal.setAttribute("inert", "");
    modal.setAttribute("aria-hidden", "true");
    document.body.removeAttribute("class");

    setTimeout(() => {
      document.body.removeAttribute("style");
    }, 10);

    showCasinoSportsMenu.value = false;
  }

  function openSideMenu() {
    console.log('Status before set',showSideMenu.value);
    showSideMenu.value = true;
    console.log('Status just before sideMenu open', showSideMenu.value);

    setTimeout(() => {
      const modal = document.getElementById('mySidenav')

      if (!modal) {
        console.error('Menu was not found!')
        return
      }

      if (modal) {
        modal.style.width = '100%';
      }
    }, 50)
    console.log('Status just after sideMenu open', showSideMenu.value);
  }

  function closeSideMenu() {
    console.log('Status just before sideMenu close', showSideMenu.value);
    showSideMenu.value = false;
    console.log('Status just after sideMenu close', showSideMenu.value);
  }

  function openDepositModal() {
    showDepositModal.value = true;
    console.log(showDepositModal.value);
    setTimeout(() => {
      const modal = document.getElementById('depost-funds')

      if (!modal) {
        console.error('Modal element not found!')
        return
      }

      const modalInstance = bootstrap.Modal.getOrCreateInstance(modal)

      modal.addEventListener(
        'hidden.bs.modal',
        () => {
          document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove())

          modal.setAttribute('inert', '')
          modal.setAttribute('aria-hidden', 'true')

          const shareBetModalElement = document.getElementById('depost-funds')
          if (shareBetModalElement) {
            const newModalInstance = bootstrap.Modal.getOrCreateInstance(shareBetModalElement)
            newModalInstance.show()

            shareBetModalElement.removeAttribute('inert')
            shareBetModalElement.setAttribute('aria-hidden', 'false')
            shareBetModalElement.focus()
          }
        },
        { once: true } // Ensures the event listener runs only once
      )

      modalInstance.show()
    }, 100)
  }

  function closeDepositModal() {
    console.log(showDepositModal.value)
    const modal = document.getElementById("depost-funds");

    if (!modal) {
      console.error("Modal element not found!");
      return;
    }

    const modalInstance = bootstrap.Modal.getInstance(modal);

    if (modalInstance) {
      modalInstance.hide();
    }

    // Remove backdrop manually (Bootstrap sometimes fails to remove it)
    document.querySelectorAll(".modal-backdrop").forEach((backdrop) => backdrop.remove());

    // Restore modal attributes
    modal.setAttribute("inert", "");
    modal.setAttribute("aria-hidden", "true");

    document.body.removeAttribute("class");

    setTimeout(() => {
      document.body.removeAttribute("style");
    }, 10);

    showDepositModal.value = false;
  }

  function openBetslipModal() {
    showBetslipModal.value = true;
    // console.log("Betslip Modal is ready to open",showBetslipModal.value);

    setTimeout(() => {
      const modal = document.getElementById('myBetSlip')

      if (!modal) {
        console.error('Modal element not found!')
        return
      }

      const modalInstance = bootstrap.Modal.getOrCreateInstance(modal)

      modal.addEventListener(
        'hidden.bs.modal',
        () => {
          document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove())

          modal.setAttribute('inert', '')
          modal.setAttribute('aria-hidden', 'true')

          const shareBetModalElement = document.getElementById('myBetSlip')
          if (shareBetModalElement) {
            const newModalInstance = bootstrap.Modal.getOrCreateInstance(shareBetModalElement)
            newModalInstance.show()

            shareBetModalElement.removeAttribute('inert')
            shareBetModalElement.setAttribute('aria-hidden', 'false')
            shareBetModalElement.focus()
          }
        },
        { once: true } // Ensures the event listener runs only once
      )

      modalInstance.show()
    }, 100)
  }

  function closeBetslipModal() {
    const modal = document.getElementById("myBetSlip");

    if (modal) {
      const modalInstance = bootstrap.Modal.getInstance(modal);
      if (modalInstance) {
        modalInstance.hide();
      }

      // Remove modal attributes
      modal.setAttribute("inert", "");
      modal.setAttribute("aria-hidden", "true");
    } else {
      console.warn("No modal found. Ensuring cleanup...");
    }

    // Remove any remaining modal backdrop
    document.querySelectorAll(".modal-backdrop").forEach((backdrop) => backdrop.remove());

    // Remove Bootstrap's modal-open class if it exists
    document.body.classList.remove("modal-open");

    // Remove inline styles added by Bootstrap
    setTimeout(() => {
      document.body.removeAttribute("style");
    }, 10);

    // console.log("We are closed!");
    showBetslipModal.value = false;
  }
  return {
    showSportsMenu, openSportsMenu, closeSportsMenu,
    showCasinoSportsMenu, openSportsMenuCasino, closeSportsMenuCasino,
    showSideMenu, openSideMenu, closeSideMenu,
    showDepositModal, openDepositModal, closeDepositModal,
    showBetslipModal, openBetslipModal, closeBetslipModal
  }
})
