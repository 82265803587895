export const trackEvent = (eventName, eventData = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: eventName,
    ...eventData,
  });

  // console.log(`Event Triggered: ${eventName}`, eventData);
};

export const trackRegistrationInitiation = (userId = null) => {
  const eventData = {
    registration_status: "started",
  };
  if (userId) eventData.user_id = userId;

  trackEvent("registration_initiated", eventData);
  console.log("Triggered: Registration Initiated", eventData);
};

export const trackRegistrationOngoing = (userId = null) => {
  const eventData = {
    status: "ongoing",
  };
  if (userId) {
    eventData.user_id = userId;
  }

  trackEvent("registration_ongoing", eventData);
  console.log("Triggered: Registration Ongoing", eventData);
};


export const trackRegistrationInitiatedError = (errorCode, errorMessage) => {
  trackEvent("registration_initiated_error", {
    error_code: errorCode,
    error_message: errorMessage
  });

  console.log("Triggered: Registration Initiated Error", { errorCode, errorMessage });
};

export const trackRegistrationCompletedError = (errorCode, errorMessage, userId) => {
  trackEvent("registration_complete_error", {
    error_code: errorCode,
    error_message: errorMessage,
    user_id: userId
  });

  console.log("Triggered: Registration Completed Error", { errorCode, errorMessage, userId });
};

export const trackGA4Deposit = (depositAmount, uniqueProfile = null) => {
  const eventData = {
    deposit_amount: depositAmount,
    status: "successful",
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("deposit_made", eventData);
  console.log("Triggered: Deposit Made", eventData);
};

export const trackWithdrawal = (withdrawalAmount, uniqueProfile = null) => {
  const eventData = {
    withdrawal_amount: withdrawalAmount,
    status: "initiated",
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("withdrawal_made", eventData);
  console.log("Triggered: Withdrawal Made", eventData);
};

export const trackSelfExclusionInitiation = (duration, uniqueProfile = null) => {
  const eventData = {
    self_exclusion_duration: duration,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("self_exclusion", eventData);
  console.log("Triggered: Self-Exclusion", eventData);
};

export const trackSelfExclusionSuccess = (duration, uniqueProfile = null) => {
  const eventData = {
    self_exclusion_duration: duration,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("self_exclusion_success", eventData);
  console.log("Triggered: Self-Exclusion Success", eventData);
};

export const trackCasinoGamePlay = (gameName, playData, uniqueProfile = null) => {
  const eventData = {
    game_name: gameName,
    ...playData,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("casino_game_play", eventData);
  console.log("Triggered: Casino Game Play", eventData);
};

export const trackRegistrationSuccess = (uniqueProfile = null) => {
  const eventData = {
    verification_status: "completed",
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("registration_success", eventData);
  console.log("Triggered: Registration Successful", eventData);
};

export const trackReferralProgram = (referralCode, referredProfile, referralLink, uniqueProfile = null) => {
  const eventData = {
    referral_code: referralCode,
    referred_profile: referredProfile,
    referral_link: referralLink
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("referral_program", eventData);
  console.log("Triggered: Referral Program", eventData);
};

export const trackAffiliateActivity = (affiliateId, action, uniqueProfile = null) => {
  const eventData = {
    affiliate_id: affiliateId,
    action: action,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("affiliate_activity", eventData);
  console.log("Triggered: Affiliate Activity", eventData);
};

export const trackGA4PlaceBet = (betCode, betAmount, uniqueProfile = null) => {
  const eventData = {
    bet_code: betCode,
    bet_amount: betAmount,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("placebet_event", eventData);
  console.log("Triggered: PlaceBet Event", eventData);
};

export const trackGA4LigiSoo = (betCode, betAmount, uniqueProfile = null) => {
  const eventData = {
    bet_code: betCode,
    bet_amount: betAmount,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("ligisoo_placebet", eventData);
  console.log("Triggered: Ligi Soo Event", eventData);
};

export const trackGA4EuroV = (gameName, playData, uniqueProfile = null) => {
  const eventData = {
    game_name: gameName,
    ...playData,
  };
  if (uniqueProfile) eventData.uniqueProfile = uniqueProfile;

  trackEvent("euroV_init", eventData);
  console.log("Triggered: EuroV Play", eventData);
};