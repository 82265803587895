<template>
  <div>
    <slot></slot>
    <div ref="sentinel" style="height: 1px;"></div>
  </div>
</template>

<script>
export default {
  name: 'InfiniteScroll',
  props: {
    onLoad: {
      type: Function,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (!this.enabled) return;
    this.observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            this.onLoad();
          }
        },
        {
          root: this.$el.parentElement,
          rootMargin: '0px',
          threshold: 1.0
        }
    );
    this.observer.observe(this.$refs.sentinel);
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>