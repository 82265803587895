import axios from 'axios';

const primaryURL = import.meta.env.VITE_BASE_FIXTURE_URL;
const fallbackURL = import.meta.env.VITE_BASE_FIXTURE_URL_FALLBACK;

const fix = axios.create({
  baseURL: primaryURL,
  timeout: 5000,
});

fix.defaults.headers.post['Content-Type'] = 'application/json';
fix.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
fix.defaults.method = 'get';

function timeoutPromise(ms) {
  return new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Health check timed out')), ms)
  );
}

const healthCheckPromise = Promise.race([
  axios.get(primaryURL),
  timeoutPromise(5000)
])
  .then(response => {
    if (response.status === 200) {
      console.log('Primary endpoint healthy.');
    } else {
      console.warn('Primary endpoint unhealthy. Switching to fallback.');
      fix.defaults.baseURL = fallbackURL;
    }
  })
  .catch(error => {
    console.warn('Primary endpoint failed health check or timed out. Switching to fallback.');
    fix.defaults.baseURL = fallbackURL;
  });

fix.interceptors.request.use(async (config) => {
  await healthCheckPromise;
  config.baseURL = fix.defaults.baseURL;
  return config;
});

export default fix;

// import axios from 'axios'
  //
  // const fix = axios.create({
  //   baseURL: import.meta.env.VITE_BASE_FIXTURE_URL
  // })
  //
  // fix.defaults.headers.post['Content-Type'] = 'application/json'
  // fix.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
  //
  // fix.defaults.method = 'get'
  //
  // export default fix

