import { defineStore } from 'pinia'
import $ from 'jquery'
import fix from '@/services/fix.js'
import { useSportStore } from '@/stores/sport.js'

export const useFixtureStore = defineStore('fixture', {
  state: () => {
    const bSlip = JSON.parse(localStorage.getItem('bSlip')) || []
    return {
      bSlip: bSlip,
      live_match_counter: 0
    }
  },
  getters: {
    get_live_match_counter: function(state) {
      return state.live_match_counter
    }
  },
  actions: {
    addPick: function(
      match_id,
      date,
      market_name,
      market_id,
      competitor_1,
      competitor_2,
      picks,
      producer_id,
      producer_status,
      status_name,
      market_status,
      game_id,
      status,
      specifier,
      category_id, category_name, tournament_id, tournament_name
    ) {
      var { active } = picks

      // console.log(specifier, status)

      //Removed to be handled on betlsip
      // Check if producer_id and producer_status need further resolution
      // if (parseInt(producer_id) === 1 && parseInt(producer_status) === 0) {
      //   return
      // }

      // Check if picks are active or status is 1
      // if (parseInt(active) === 0 || parseInt(status) === 1) {
      //   console.log('Exiting due to active#' + active + ' status#' + status)
      //   return
      // }

      // Retrieve or initialize 'bSlip'
      var bSlip = useFixtureStore().getObject('bSlip') || []

      // Ensure 'bSlip' is an array
      if (!Array.isArray(bSlip)) {
        bSlip = []
      }

      // Ensure 'picks' is an object
      if (typeof picks !== 'object') {
        console.error('Invalid picks object:', picks)
        return
      }

      // Create a unique identifier for the pick
      const pickId = this.generatePickID(match_id, market_id, picks.outcome_id, specifier);

      // Set properties for 'picks'
      picks.match_id = match_id
      picks.date = date
      picks.sport_id = useSportStore().sportId
      picks.producer_id = producer_id
      picks.market_name = market_name
      picks.market_id = market_id
      picks.competitor_1 = competitor_1
      picks.competitor_2 = competitor_2
      picks.game_id = game_id
      picks.specifier = specifier
      picks.category_id = category_id
      picks.country = country
        picks.tournament_id = tournament_id
        picks.tournament = tournament
      picks.id = pickId

      // Ensure 'bSlip' length is not exceeded
      if (bSlip.length > 29) {
        this.setError('Error', 'Maximum number of games reached')
        return
      }

      var isunselect = false

      // Iterate over 'bSlip' to check for existing picks
      bSlip.forEach(function(v, k) {
        if (v && v.id === pickId) {
          isunselect = true
        }

        if (v && v.match_id === match_id) {
          delete bSlip[k]
        }
      })

      // If pick is not already selected, add it to 'bSlip'
      if (!isunselect) {
        bSlip.push(picks)
      }

      // Remove undefined/null elements from 'bSlip'
      bSlip = bSlip.filter(function(v) {
        return v !== undefined && v !== null
      })

      // Save updated 'bSlip'
      this.saveObject('bSlip', bSlip)
      useFixtureStore().autoRefreshUI('addPick')
    },

    addSelections(selections) {
      if (!Array.isArray(selections)) {
        console.error("Selections must be an array");
        return;
      }

      let bSlip = this.getObject('bSlip') || [];

      if (!Array.isArray(bSlip)) {
        bSlip = [];
      }

      selections.forEach((selection) => {
        if (!selection.match_id || !selection.market_id || !selection.outcome_id) {
          console.error("Invalid selection data", selection);
          return;
        }

        const pickId = this.generatePickID(
          selection.match_id,
          selection.market_id,
          selection.outcome_id,
          selection.specifier || ""
        );

        selection.id = pickId;

        const existsIndex = bSlip.findIndex((pick) => pick.id === pickId);
        if (existsIndex !== -1) {
          bSlip[existsIndex] = selection;
        } else {
          bSlip.push(selection);
        }
      });

      if (bSlip.length > 30) {
        this.setError('Error', 'Maximum number of games reached');
        return;
      }

      this.saveObject('bSlip', bSlip);
      this.autoRefreshUI('addSelections');
    },

    // generatePickID: function(match_id, market_id, outcome_id, specifier) {
    //   const cleanSpecifier = specifier.replace(/[^a-zA-Z0-9]/g, '');
    //   const cleanOutcomeId = outcome_id.replace(/[^a-zA-Z0-9]/g, '');
    //   return `match-${match_id}-market-${market_id}-outcome-${cleanOutcomeId}-specifier-${cleanSpecifier}`
    // },
    generatePickID: function(match_id, market_id, outcome_id, specifier) {
      // Replace invalid characters with a safe alternative
      const sanitize = (str) => str.replace(/[^a-zA-Z0-9-_]/g, '').replace(/\s+/g, '-');
      // const cleanMarketId = sanitize(market_id);
      const cleanSpecifier = sanitize(specifier);
      const cleanOutcomeId = sanitize(outcome_id);
      return `match-${match_id}-market-${market_id}-outcome-${cleanOutcomeId}-specifier-${cleanSpecifier}`;
    },

    setBetSlip(betSlip) {
      this.bSlip = betSlip
    },
    saveObject: function(key, value) {
      value = JSON.stringify(value)

      if (typeof Storage !== 'undefined') {
        localStorage.setItem(key, value)
      } else {
        document.cookie = key + '=' + value
      }
    },
    getLiveMatchCounter: function() {
      var path = import.meta.env.VITE_BASE_FIXTURE_URL + '/live/counter'
      fix
        .get(path)
        .then((res) => {
          var sp = res.data.total
          this.live_match_counter = sp
        })
        .catch((error) => {
          console.log(error)
        })
    },
    autoRefreshUI: function(callersName) {
      const betsObject = this.getBetSlip(0);
      const bets = betsObject.picks;

      // Remove 'selected-odds' class and add 'unselected-odds' class
      $('.selected-odds').removeClass('selected-odds').addClass('unselected-odds');

      $.each(bets, function(matchID, b) {
        const oddsClass = `#${b.id}`;
        // Remove 'unselected-odds' class and add 'selected-odds' class for selected odds
        $(oddsClass).removeClass('unselected-odds').addClass('selected-odds');

        const boostedOddsClass = `#boosted-${b.id}`;
        // Remove 'unselected-odds' class and add 'selected-odds' class for boosted odds
        $(boostedOddsClass).removeClass('unselected-odds').addClass('selected-odds');
      });

      this.saveObject('betslip', betsObject);
      this.setBetSlip(betsObject);
      this.checkSlipCounter(betsObject.total);
    },
    getBetSlip: function(stake) {
      var bSlip = this.getObject('bSlip')

      if (!bSlip || typeof bSlip !== 'object') {
        bSlip = []
      }

      var total = bSlip.length

      var bs = {}
      var totalOdds = 1

      if (total > 0) {
        bSlip.forEach(function(v) {
          bs[v.match_id] = v
          if (!isNaN(parseFloat(v.odds))) {
            var odds = parseFloat(v.odds)
            totalOdds *= odds
            if (totalOdds >= 1000000) totalOdds = 1000000
          }
        })
      }

      if (stake === 0) {
        stake = parseFloat(this.getValue('stake') || 0)
      }

      var {
        wagerTax,
        stakeAfterTax,
        withholdingTax,
        payout,
        winning
      } = this.calculateTaxesAndWinnings(stake, totalOdds)

      var res = {
        picks: bs,
        payout: payout,
        stake: stake,
        wager_tax: wagerTax,
        stake_after_tax: stakeAfterTax,
        winning: winning,
        tax: withholdingTax,
        total: total,
        odds: parseFloat(totalOdds.toFixed(2))
      }

      return res
    },
    getObject: function(key) {
      if (typeof Storage !== 'undefined') {
        var post = localStorage.getItem(key)

        if (post === undefined) {
          return false
        }

        return JSON.parse(post)
      } else {
        return JSON.parse(this.getCookie(key))
      }
    },
    getValue: function(key) {
      if (typeof Storage !== 'undefined') {
        var post = localStorage.getItem(key)
        if (post === 'undefined') {
          return ''
        }

        return post
      } else {
        return this.getCookie(key)
      }
    },
    clearBetSlip: function() {
      this.saveObject('bSlip', [])
      this.saveObject('betslip', [])
      this.autoRefreshUI('clearBetSlip')
      this.setBetSlip([])
    },
    removePick: function(match_id) {
      var bSlip = this.getObject('bSlip')

      if (!bSlip || bSlip === '') {
        bSlip = []
      }

      bSlip = bSlip.filter(v => v.match_id !== match_id)

      this.saveObject('bSlip', bSlip)
      this.autoRefreshUI('removePick')

      if (bSlip.length === 0) {
        $('.faso-close').click()
      }
    },
    checkSlipCounter: function(slips) {
      if (isNaN(slips)) {
        slips = 0
      }

      slips = parseInt(slips)

      if (slips === 0) {
        $('.betslip-counter-wrap').addClass('hidden')
      } else {
        $('.betslip-counter-wrap').removeClass('hidden')

        if (slips > 30) {
          $('#slip_c').html(30)
          $('.slip-counter').html(30)
        } else {
          $('#slip_c').html(slips)
          $('.slip-counter').html(slips)
        }
      }
    },
    calculateTaxesAndWinnings(stake, totalOdds) {
      var taxOnStake = 15.0
      var wagerTax = (stake * taxOnStake) / 100
      var stakeAfterTax = stake - wagerTax
      var winning = stakeAfterTax * totalOdds

      if (winning >= 1000000) {
        winning = 1000000
      }

      var netWinning = winning - stakeAfterTax
      var withholdingTax = (netWinning * 20) / 100
      var payout = winning - withholdingTax

      return { wagerTax, stakeAfterTax, withholdingTax, payout, winning }
    }
  }
})
