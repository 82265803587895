<template>
  <div v-if="alerts.length" class="global-alert-wrapper">
    <div
      v-for="(alert, index) in alerts"
      :key="index"
      :class="['alert', alert.type]"
      class="global-alert"
    >
      {{ alert.message }}
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import emitter from '@/services/eventbus.js'

export default {
  name: 'GlobalAlert',
  setup() {
    const alerts = ref([])
    let timeoutId = null

    const addAlert = (message, type) => {
      if (alerts.value.length) {
        alerts.value[0] = { message, type }
      } else {
        alerts.value.push({ message, type })
      }

      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      timeoutId = setTimeout(() => {
        alerts.value.shift()
        timeoutId = null
      }, 5000)
    }

    const handleSuccess = (message) => addAlert(message, 'success')
    const handleInfo = (message) => addAlert(message, 'info')
    const handleError = (message) => addAlert(message, 'error')

    onMounted(() => {
      emitter.on('setSuccess', handleSuccess)
      emitter.on('setError', handleError)
      emitter.on('setInfo', handleInfo)
      emitter.on('error', handleError)
    })

    onUnmounted(() => {
      emitter.off('setSuccess', handleSuccess)
      emitter.off('setError', handleError)
      emitter.off('setInfo', handleInfo)
      emitter.off('error', handleError)
    })

    return { alerts }
  }
}
</script>

<style scoped>
.global-alert-wrapper {
  position: fixed;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
  width: 100%;
  max-width: 540px;
}

.global-alert {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
}

.alert.info {
  background-color: yellow;
  color: black;
}

.alert.success {
  background-color: green;
}

.alert.error {
  background-color: red;
}
</style>