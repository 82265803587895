import axios from 'axios'

const affiliate = axios.create({
  baseURL: import.meta.env.VITE_BASE_AFFILIATE_URL
})

affiliate.defaults.headers.post['Content-Type'] = 'application/json'
affiliate.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

affiliate.defaults.method = 'get'

export default affiliate