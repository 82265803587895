import { defineStore } from 'pinia';
import walletserve from '@/services/walletserve.js';
import affiliate from '@/services/affiliate.js';
import { useAuthStore } from '@/stores/auth.js';
import emitter from '@/services/eventbus.js';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';

export const useBalanceStore = defineStore('balance', {
  state: () => ({
    balance: 0,
    wbalance: 0,
    sessionExpired: false,
    balanceFetched: false,
    fetchingBalance: false,
    summaryFetched: false,
    fetchingSummary: false,
    summaryData: 0,
  }),
  actions: {
    debouncedFetchBalance: debounce(async function() {
      if (this.fetchingBalance || this.balanceFetched) {
        return {
          balance: this.balance,
          wbalance: this.wbalance
        };
      }

      this.fetchingBalance = true;

      const authStore = useAuthStore();
      const auth = authStore.user?.auth ?? '';

      if (!auth) {
        this.fetchingBalance = false;
        return {
          balance: this.balance,
          wbalance: this.wbalance
        };
      }

      try {
        const path = `${import.meta.env.VITE_BASE_WALLET_URL}/balance`;
        const response = await walletserve.get(path, {
          headers: {
            'api-key': auth
          }
        });

        this.balance = parseFloat(response.data.b1 || 0).toFixed(2);
        this.wbalance = parseFloat(response.data.b2 || 0).toFixed(2);
        this.sessionExpired = false;
        this.balanceFetched = true;

        return {
          balance: this.balance,
          wbalance: this.wbalance
        };
      } catch (error) {
        if (error.response && [401, 400, 428].includes(parseInt(error.response.status))) {
          if (!this.sessionExpired) {
            emitter.emit('setInfo', 'Your session on this device has expired');
            this.sessionExpired = true;
          }
          await useAuthStore().logout();
        } else {
          this.balance = 0;
          this.wbalance = 0;
        }
        return {
          balance: this.balance,
          wbalance: this.wbalance
        };
      } finally {
        this.fetchingBalance = false;
      }
    }, 300),

    async fetchBalance() {
      return this.debouncedFetchBalance();
    },

    debouncedFetchSummary: debounce(async function() {
      if (this.fetchingSummary || this.summaryFetched) {
        return this.summaryData;
      }

      this.fetchingSummary = true;

      const authStore = useAuthStore();
      const auth = authStore.user?.auth ?? '';

      if (!auth) {
        this.fetchingSummary = false;
        return this.summaryData;
      }

      try {
        const start = '2024-10-14';
        const end = dayjs().format('YYYY-MM-DD');

        const path = `${import.meta.env.VITE_BASE_AFFILIATE_URL}/summary`;

        const response = await affiliate.get(path, {
          headers: {
            'api-key': auth
          },
          params: {
            start,
            end
          }
        });

        // console.log('Summary Response:', response);

        this.summaryData = response.data.wallet_balance ?? 0;
        this.summaryFetched = true;

        return this.summaryData;
      } catch (error) {
        console.error('Error fetching summary data:', error);
        this.summaryData = 0;
      } finally {
        this.fetchingSummary = false;
      }
    }, 300),

    async fetchSummary() {
      return this.debouncedFetchSummary();
    },

    resetBalanceFetched() {
      this.balanceFetched = false;
      this.summaryFetched = false;
    }
  }
});