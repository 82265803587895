// stores/referral.js
import { defineStore } from 'pinia'
import affiliate from '@/services/affiliate.js'
import { useAuthStore } from "@/stores/auth.js";

export const useReferralStore = defineStore('referral', {
    state: () => ({
        referralCode: null,
        affiliateCode: false,
    }),
    actions: {
        async fetchReferralCode() {
            const user = useAuthStore()
            if (user.user) {
                try {
                    const path = import.meta.env.VITE_BASE_AFFILIATE_URL + '/whoami'
                    const response = await affiliate.get(path, {
                        headers: {
                            'api-key': user.user.auth,
                        },
                    })
                    this.referralCode = response.data.data
                    this.affiliateCode = !!response.data.data
                } catch (error) {
                    console.error('Error fetching referral code:', error)
                }
            }
        },

        async createAffiliate() {
            if (this.referralCode) {
                console.log('Affiliate already exists, skipping creation.');
                return true;
            }
            if (this.referralCode && this.affiliateCode) {
                return true
            }
            try {
                const authStore = useAuthStore()
                const referralStore = useReferralStore()
                let code = referralStore.referralCode
                if (code !== null && code !== undefined && code !== ""){
                    return true
                }
                const userData = authStore.user
                const token = userData?.auth
                const msisdn = userData?.msisdn
                if (!token || !msisdn) {
                    console.error('Missing auth token or msisdn')
                    this.affiliateCode = false
                    return false
                }

                const affiliatePath = `${import.meta.env.VITE_BASE_AFFILIATE_URL}/affiliate`
                const affiliateData = {
                    code: '',
                    msisdn,
                }
                const response = await affiliate.post(affiliatePath, affiliateData, {
                    headers: { 'api-key': token }
                })
                if (response.status === 201) {
                    this.affiliateCode = true
                    return true
                } else {
                    this.affiliateCode = false
                    return false
                }
            } catch (error) {
                console.error('Error creating affiliate:', error)
                this.affiliateCode = false
                return false
            }
        }
    },
})