// src/utils/functions.js
export function generateHash() {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

import CryptoJS from 'crypto-js'

const generateKey = (keyword) => {
  return CryptoJS.SHA256(keyword).toString(CryptoJS.enc.Hex)
}

const secretKey = generateKey('MaybetsAuth');

export function encryptData(data) {
  const stringData = JSON.stringify(data);
  return CryptoJS.AES.encrypt(stringData, secretKey).toString();
}

export function decryptData(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
}

// Cookies Functions
export function setCookies(cookies) {
  const date = new Date();
  date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  cookies.forEach(({ name, value }) => {
    document.cookie = `${name}=${encodeURIComponent(value)}; ${expires}; Secure; SameSite=Strict; path=/`;
  });
}

export function getCookieValue(key) {
  return document.cookie
    .split('; ')
    .reduce((acc, cookie) => {
      const [k, v] = cookie.split('=');
      acc[k] = decodeURIComponent(v);
      return acc;
    }, {})[key] || null;
}

export function getDecryptedCookieValue(key) {
  const cookies = document.cookie
    .split('; ')
    .reduce((acc, cookie) => {
      const [k, v] = cookie.split('=');
      acc[k] = decodeURIComponent(v); // Decode cookie value
      return acc;
    }, {});

  const encryptedCookieValue = cookies[key];

  if (!encryptedCookieValue) {
    return null;
  }

  try {
    return decryptData(encryptedCookieValue)
  } catch (error) {
    console.error('Error decrypting the cookie value:', error);
    return null;
  }
}

export function clearAllCookies() {
  document.cookie
    .split(';')
    .forEach(cookie => {
      const name = cookie.split('=')[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;`;
    });
}

export function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict";
}

export function getChannelId (userAgent) {
  if (/Android/i.test(userAgent) && /app1\.maybets\.com/.test(userAgent)) {
    return 3;
  } else if (/iPhone|iPad|iPod/i.test(userAgent) && /app1\.maybets\.com/.test(userAgent)) {
    return 4;
  } else if (/Mobile|Android|iPhone|iPad|iPod/i.test(userAgent)) {
    return 2;
  } else {
    return 1;
  }
}

export function getBrowser(userAgent) {
  if (/Chrome/i.test(userAgent) && !/Edge|Edg/i.test(userAgent)) {
    return 'Chrome';
  } else if (/Firefox/i.test(userAgent)) {
    return 'Firefox';
  } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
    return 'Safari';
  } else if (/Edge|Edg/i.test(userAgent)) {
    return 'Edge';
  } else if (/MSIE|Trident/i.test(userAgent)) {
    return 'Internet Explorer';
  } else {
    return 'Unknown';
  }
}


export function handleMessageFromJS(auth, profileId) {
  try {
    if (typeof window.Android !== 'undefined' && typeof window.Android.postMessage === 'function') {
      const targetWindow = window;
      const mydata = {
        auth: auth,
        profile: profileId
      };
      let data = {
        type: "messageFromJS",
        data: mydata
      };
      targetWindow.Android.postMessage(JSON.stringify(data));
    } else {
      console.warn('Android interface not available or postMessage not defined.');
    }
  } catch (error) {
    console.error('Error in handleMessageFromJS:', error);
  }
}