import { defineStore } from 'pinia'
import $ from 'jquery'
import { useAuthStore } from '@/stores/auth.js'
import jpserve from '@/services/jpserve.js'
import emitter from '@/services/eventbus.js'

export const useJackpotStore = defineStore('jackpot', {
  state: () => ({

    jackpotData: JSON.parse(localStorage.getItem('jackpotDetails')),
    has_suspended_jackpot_picks: false,
    categoryId: null,
    jSlip: JSON.parse(localStorage.getItem('jSlip')) || {},
    results: [],
    error: null
  }),
  actions: {
    getOddID: function(match, market, outcome, specifier, alias) {
      var id =
        alias +
        '-match-' +
        match +
        '-market-' +
        market +
        '-outcome-' +
        outcome +
        '-specifier-' +
        specifier

      id = id.replace(/\|/g, '-and-')
      id = id.replace(/=/g, '-eq-')
      id = id.replaceAll('+', '')

      return id
    },
    async fetchResults() {
      try {
        const categories = [5, 6]
        const requests = categories.map(category_id =>
          jpserve.get(`https://jackpot.maybets.com/jackpot/${category_id}/results`)
        )
        const responses = await Promise.all(requests)
        this.results = responses.flatMap(response => response.data)
      } catch (error) {
        this.error = error.response ? error.response.data.error_message : error.message
      }
    },
    async placeBet(betPayload) {
      this.loading = true
      this.error = null
      const authStore = useAuthStore()
      const apiKey = authStore.user.auth

      const url = `${import.meta.env.VITE_BASE_JACKPOT_URL}/jackpot/${this.jackpotData.id}/bet`

      try {
        const response = await jpserve.post(url, betPayload, {
          headers: {
            'api-key': apiKey,
            'Content-Type': 'application/json'
          }
        })
        if (this.jackpotData.jackpot_category_id === 6) {
          localStorage.removeItem('jslip6');
          localStorage.removeItem('jBSlip6');
        } else if (this.jackpotData.jackpot_category_id === 5) {
          localStorage.removeItem('jslip5');
          localStorage.removeItem('jBSlip5');
        }

        // console.log('Bet placed successfully:', response.data)
        emitter.emit('setSuccess', 'Bet Placed Successfully')
        this.clearJackpotBetSlip()

        return response.data
      } catch (error) {
        if (error.response) {
          emitter.emit('error', error.response?.data?.error_message || 'Failed To Place Bet. Please try again.')
        }
        throw error
      } finally {
        this.loading = false
      }
    },
    getRandomInt: function(max) {
      return Math.floor(Math.random() * max)
    },
    autoPick: function() {
      this.clearJackpotBetSlip()
      var max = 17
      var r = 0
      while (r < max) {
        // generate random between 0 and 2
        var i = this.getRandomInt(3)
        var id = 'r' + r + 'c' + i
        // console.log('Wants to click ' + id);
        //this.jQuery("#"+id).click();
        $('[unique=' + id + ']').click()
        r++
      }
    },
    setCategoryId(id) {
      this.categoryId = id
    },
    setJPBetSlip(betSlip) {
      this.jSlip = betSlip
    },
    setJackpot(jackpot) {
      this.jackpotData = jackpot
    },
    saveObject: function(key, value) {
      value = JSON.stringify(value)

      if (typeof Storage !== 'undefined') {
        localStorage.setItem(key, value)
      } else {
        document.cookie = key + '=' + value
      }
    },
    autoRefreshJackpotUI: function() {
      var betsObject = this.getJackpotBetSlip(0)
      var bets = betsObject.picks

      $('.jselected-odds').removeClass('jselected-odds').addClass('unselected-odds');

      $.each(bets, (matchID, b) => {
        // const id = this.getOddID(matchID, b.market_id, b.outcome_id, b.specifier, 'jpodd')
        // let oddsClass = '#' + id
        const oddsClass = `#${b.id}`;
        $(oddsClass).removeClass('unselected-odds').addClass('jselected-odds');

        // oddsClass = '#boosted-' + id
        const boostedOddsClass = `#boosted-${b.id}`;
        $(boostedOddsClass).removeClass('unselected-odds').addClass('jselected-odds');
      })

      this.saveObject('jackpotbetslip', betsObject)

      this.setJPBetSlip(betsObject)

      this.checkJackpotSlipCounter(betsObject.total)
    },

    updateJackpotOdd: function(id, odds, previous_odds, active, status) {
      var hasChanged = false

      if (parseFloat(odds) !== parseFloat(previous_odds)) {
        hasChanged = true
      }

      var bSlip = this.getObject('jSlip')

      if (!bSlip || bSlip === '' || bSlip === '') {
        bSlip = []
      }

      var bt = []

      $.each(bSlip, function(k, v) {
        if (v !== undefined && v !== null) {
          if (v.id === id) {
            v.odd = odds
            v.odds = odds

            if (parseInt(v.active) !== parseInt(active)) {
              hasChanged = true
            }

            if (parseInt(v.status) !== parseInt(status)) {
              hasChanged = true
            }

            v.active = active
            v.status = status
            v.previous_odds = previous_odds
          }

          bt.push(v)
        }
      })

      if (hasChanged) {
        this.saveObject('jSlip', bt)
        this.autoRefreshJackpotUI('updateJackpotOdd')
      }
      // }
    },

    getValue: function(key) {
      if (typeof Storage !== 'undefined') {
        var post = localStorage.getItem(key)
        if (post === 'undefined') {
          return ''
        }

        return post
      } else {
        return this.getCookie(key)
      }
    },

    hasSuspendedJackpotPicks: function() {
      var bSlip = this.getObject('jSlip')

      if (!bSlip || bSlip === '' || bSlip === '') {
        bSlip = []
      }

      var suspended = 0

      $.each(bSlip, function(k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) !== 1 || parseInt(v.status) !== 0) {
            suspended++
          }
        }
      })

      return suspended > 0
    },

    removeSuspendedJackpotPicks: function() {
      var bSlip = this.getObject('jSlip')

      if (!bSlip || bSlip === '' || bSlip === '') {
        bSlip = []
      }

      var bt = []

      $.each(bSlip, function(k, v) {
        if (v !== undefined && v !== null) {
          //a suspended/deactivated outcome has active = 0 and status > 0
          if (parseInt(v.active) === 1 && parseInt(v.status) === 0) {
            bt.push(v)
          }
        }
      })

      this.saveObject('jSlip', bt)
      this.autoRefreshJackpotUI('removeSuspendedJackpotPicks')
    },

    checkJackpotSlipCounter: function(slips) {
      if (isNaN(slips)) {
        slips = 0
      }

      slips = parseInt(slips)

      if (slips === 0) {
        $('#jbetslip-counter-wrap').addClass('hidden')
        $('.jackpot-slip-counter').html('0')
        $('#jackpot-slip_c').html('0')
        //$("#counter").html("0");
      } else {
        $('#jbetslip-counter-wrap').removeClass('hidden')

        if (slips > 19) {
          $('#jackpot-slip_c').html(19)
          $('.jackpot-slip-counter').html(19)
        } else {
          $('#jackpot-slip_c').html(slips)
          $('.jackpot-slip-counter').html(slips)
        }
      }
    },

    removeJackpotPick: function(match_id) {
      var bSlip = this.getObject('jSlip')

      if (!bSlip || bSlip === '' || bSlip === '') {
        bSlip = []
      }

      if (bSlip.length > 0) {
        $.each(bSlip, function(k, v) {
          if (v !== undefined && v !== null) {
            if (v.match_id === match_id) {
              delete bSlip[k]
            }
          }
        })
      }

      var bt = []

      $.each(bSlip, function(k, v) {
        if (v !== undefined || v) {
          bt.push(v)
        }
      })

      this.saveObject('jSlip', bt)
      this.autoRefreshJackpotUI('removeJackpotPick')

      if (bt.length === 0) {
        $('.faso-close').click()
      }
    },

    getJackpotBetSlip: function(stake) {
      var bSlip = this.getObject('jSlip')

      if (!bSlip || bSlip === '' || bSlip === '') {
        bSlip = []
      }

      var total = bSlip.length

      if (total === undefined) {
        total = Object.keys(bSlip).length
      }

      var bs = {}
      var totalOdds = parseFloat(1)

      if (total > 0) {
        $.each(bSlip, function(k, v) {
          bs[v.match_id] = v
          var odds = parseFloat(v.odd)
          totalOdds = totalOdds * odds
        })
      }

      // get total odds

      if (stake === 0) {
        stake = this.getValue('stake')
      }

      if (parseInt(stake) === 0) {
        //stake = 50;
      }

      var taxOnStake = 15.0

      var wagerTax = (stake * taxOnStake) / 100

      // var stake = stakeBeforeTax / 1.2;
      var stakeAftertax = stake - wagerTax

      var winning = stakeAftertax * totalOdds

      if (winning > 1000500) {
        winning = 1000500
      }

      var netWinning = winning - stakeAftertax
      var withholdingTax = netWinning * 0.2
      var payout = winning - withholdingTax

      var res = {}
      res.picks = bs
      res.payout = payout
      res.stake = stake
      res.wager_tax = wagerTax
      res.stake_after_tax = stakeAftertax
      res.winning = winning
      res.tax = withholdingTax
      res.total = total
      res.odds = parseFloat(totalOdds).toFixed(3)
      return res
    },

    clearJackpotBetSlip: function() {
      this.saveObject('jSlip', [])
      this.saveObject('jackpotbetslip', [])
      this.autoRefreshJackpotUI('clearJackpotBetSlip')
      this.setJPBetSlip([])
    },

    getObject: function(key) {
      if (typeof Storage !== 'undefined') {
        var post = localStorage.getItem(key)

        if (post === undefined) {
          return false
        }

        return JSON.parse(post)
      } else {
        return JSON.parse(this.getCookie(key))
      }
    },

    /*setHasSuspendedJackpotPicks: function (
      { commit },
      has_suspended_jackpot_picks
    ) {
      commit("setHasSuspendedJackpotPicks", has_suspended_jackpot_picks);
    },*/
    /*setJackPotID({ commit }, id) {
      commit('setJackPotID', id);
    },*/
    setValue: function(key, value) {
      if (typeof Storage !== 'undefined') {
        localStorage.setItem(key, value)
      } else {
        document.cookie = key + '=' + value
      }
    }
    /*setJackpotBetSlip: function ({ commit }, jackpotbetslip) {
      commit("setJackpotBetSlip", jackpotbetslip);
    },*/

  }
})
