import { defineStore } from 'pinia'
import $ from 'jquery'

export const useFreebetStore = defineStore('freebet', {
  state: () => {
    return {
      freebetslip: JSON.parse(localStorage.getItem('fslip')) || {
        picks: [],
        total: 0,
        payout: 0,
        winning: 0,
        tax: 0,
        odds: 0,
        stake: 0,
        stake_tax: 0
      },
      freeBetBalance: [],
      live_match_counter: 0
    }
  },
  actions: {
    getOddID(match, market, outcome, specifier, alias) {
      let id = `${alias}-match-${match}-market-${market}-outcome-${outcome}-specifier-${specifier}`
      id = id.replace(/\|/g, '-and-')
      id = id.replace(/=/g, '-eq-')
      id = id.replaceAll('+', '')
      return id
    },
    setFBetSlip(betSlip) {
      this.freebetslip = betSlip
    },
    saveObject(key, value) {
      value = JSON.stringify(value)
      if (typeof Storage !== 'undefined') {
        localStorage.setItem(key, value)
      } else {
        document.cookie = `${key}=${value}`
      }
    },

    autoRefreshFreebetUI() {

      const betsObject = this.getFreeBetSlip(30)
      const bets = betsObject.picks

      $('.fselected-odds').removeClass('fselected-odds').addClass('unselected-odds');

      $.each(bets, function(matchID, b) {
        const oddsClass = `#${b.id}`;
        $(oddsClass).removeClass('unselected-odds').addClass('fselected-odds');
      });
      this.saveObject('freebetslip', betsObject)
      this.setFBetSlip(betsObject)
      this.checkSlipCounter(betsObject.total)
    },
    getFreeBetSlip(stake) {
      // console.log('Here')
      let fSlip = this.getObjectF('fslip')
      // console.log('Here is the  fslip', fSlip)
      if (!fSlip || fSlip === '') {
        fSlip = []
      }
      let total = fSlip.length
      if (total === undefined) {
        total = Object.keys(fSlip).length
      }
      const bs = {}
      let totalOdds = 1
      if (total > 0) {
        fSlip.forEach((v) => {
          bs[v.match_id] = v
          const odds = parseFloat(v.odd)
          totalOdds *= odds
        })
      }
      if (stake === 0) {
        stake = this.getValue('stake')
      }
      if (parseInt(stake) === 0) {
        // Handle case for stake being 0 if needed
      }
      const taxOnStake = 15.0
      const wagerTax = (stake * taxOnStake) / 100
      const stakeAfterTax = stake - wagerTax
      let winning = stakeAfterTax * totalOdds
      if (winning > 1000500) {
        winning = 1000500
      }
      const netWinning = winning - stakeAfterTax
      const withholdingTax = netWinning * 0.2
      const payout = winning - withholdingTax
      return {
        picks: bs,
        payout,
        stake,
        wager_tax: wagerTax,
        stake_after_tax: stakeAfterTax,
        winning,
        tax: withholdingTax,
        total,
        odds: totalOdds.toFixed(3)
      }
    },
    getObjectF(key) {
      if (typeof Storage !== 'undefined') {
        const post = localStorage.getItem(key)
        if (post === undefined) {
          return false
        }
        return JSON.parse(post)
      } else {
        return JSON.parse(this.getCookie(key))
      }
    },
    getValue(key) {
      if (typeof Storage !== 'undefined') {
        const post = localStorage.getItem(key)
        if (post === 'undefined') {
          return ''
        }
        return post
      } else {
        return this.getCookie(key)
      }
    },
    clearBetFBSlip: function() {
      this.saveObject('fSlip', [])
      this.saveObject('freebetslip', [])
      this.autoRefreshFreebetUI('clearBetFBSlip')
      this.setFBetSlip([])
    },
    removeFBPick: function(match_id) {
      let fSlip = this.getObjectF('fSlip')
      if (!fSlip || fSlip === '') {
        fSlip = []
      }
      fSlip = fSlip.filter(v => v.match_id !== match_id)
      this.saveObject('fSlip', fSlip)
      this.autoRefreshFreebetUI('removeFBPick')
      if (fSlip.length === 0) {
        $('.faso-close').click()
      }
    },
    checkSlipCounter(slips) {
      if (isNaN(slips)) {
        slips = 0
      }
      slips = parseInt(slips)
      if (slips === 0) {
        $('.betslip-counter-wrap').addClass('hidden')
      } else {
        $('.betslip-counter-wrap').removeClass('hidden')
        if (slips > 30) {
          $('#slip_c').html(30)
          $('.slip-counter').html(30)
        } else {
          $('#slip_c').html(slips)
          $('.slip-counter').html(slips)
        }
      }
    },
    calculateTaxesAndWinnings(stake, totalOdds) {
      const taxOnStake = 15.0
      const wagerTax = (stake * taxOnStake) / 100
      const stakeAfterTax = stake - wagerTax
      let winning = stakeAfterTax * totalOdds
      if (winning > 1000500) {
        winning = 1000500
      }
      const netWinning = winning - stakeAfterTax
      const withholdingTax = netWinning * 0.2
      const payout = winning - withholdingTax
      return {
        wagerTax,
        stakeAfterTax,
        withholdingTax,
        payout,
        winning
      }
    }
  }
})